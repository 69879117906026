<template>
  <main class="container">
    <!--ALERT NOTIFICATION-->
    <div class="toaster show">
      <div
        class="toaster__container"
        v-if="alert.show"
        v-bind:class="{
          'toaster--success': alert.status === 'success',
          'toaster--error': alert.status === 'fail',
          'toaster--warn': alert.status === 'warn',
        }"
      >
        <div>
          <div class="text-right">
            <span class="fa fa-times" @click="alert.show = false"></span>
          </div>
          <span class="d-block text--small text--600 text--white">{{
            alert.title
          }}</span>
          <span class="d-block text--tiny text--400 text--white">{{
            alert.description
          }}</span>
        </div>
      </div>
    </div>

    <!--CONFIRM DIALOG-->
    <div
      class="modal modal__confirm show"
      id="confirmModal"
      tabindex=""
      role="dialog"
      aria-labelledby="modalLabel"
      v-bind:style="{ display: confirm ? 'block' : 'none' }"
      aria-hidden="true"
    >
      <div class="w-100 h-100 d-flex">
        <div class="modal__dialog modal__dialog_alert" role="document">
          <div class="modal__header mb-3">
            <div class="d-flex align-items-center justify-content-between">
              <span class="modal__header__title text--capital">
                confirmation
              </span>
              <a
                class="modal__close modal__close--white d-flex align-items-center justify-content-between"
                @click="confirmSave()"
              >
                <span class="text--capital text--regular"> close </span>
                <ion-icon name="close-outline"></ion-icon>
              </a>
            </div>
            <span class="modal__header__subtitle text--capital">
              Create New Loan Account
            </span>
          </div>
          <div class="modal__content">
            <div class="modal__body">
              <h6
                class="modal__head__subtitle text--capital mb-3"
                v-if="this.disbursementDetails.bankAccountName == null"
              >
                Account Name could not be verified!
              </h6>

              <h5 class="modal__head__subtitled text--capital mb-3">
                Are you sure you want to proceed?
              </h5>

              <p class="form__label"></p>
              <div class="d-flex mt-5">
                <button
                  data-dismiss="#confirmModal"
                  class="button button--lg modal__confirm__button modal__confirm__button--cancel mr-2"
                  @click="confirmSave()"
                >
                  cancel
                </button>
                <button
                  data-dismiss="#confirmModal"
                  class="button button--lg modal__confirm__button modal__confirm__button--delete ml-2 save-changes"
                  @click="save()"
                >
                  {{ process ? "Saving..." : "Save" }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--CUSTOM FIELD MODAL-->
    <AddCustomFieldModal
      :customFieldSet="uiProps.customFieldSet"
      @update="updateCustomFieldDefaultState"
    />

    <button
      data-modal="#insertCustomFieldModal"
      ref="cfmodal"
      style="visibility: hidden"
    >
      click
    </button>

    <!--CREATE LOAN ACCOUNT PAGE-->
    <div class="row justify-content-center">
      <div class="col-8 mb-5">
        <h5 class="modal__head__title text--capital">
          Create New Loan Account
        </h5>
        <form>
          <div class="form__item mt-5">
            <label class="form__label text--regular text--black"
              >Account Recipient</label
            >
            <h2
              class="text--capital text--large text--primary"
              v-if="whatPage === 'customer'"
            >
              {{ accountRecipient() }}
              <span
                class="error--text text--tiny"
                v-if="$v.form.$dirty && !$v.form.selectedCustomer.required"
              >
                Customer is required
              </span>
            </h2>
            <template v-else>
              <multiselect
                :class="[
                  $v.form.$dirty && !$v.form.selectedCustomer.required
                    ? 'input-error'
                    : '',
                ]"
                v-model="form.selectedCustomer"
                id="ajax"
                label="customerNameWithID"
                track-by="customerKey"
                placeholder="Type to search for client by name"
                open-direction="bottom"
                :options="sortedCustomers"
                :multiple="false"
                :searchable="true"
                :internal-search="true"
                :clear-on-select="false"
                :close-on-select="true"
                :options-limit="300"
                :limit="7"
                :max-height="800"
                :loading="loading"
                :preselect-first="false"
                :disabled="isFromCustomer"
              >
                <template slot="tag" slot-scope="{ option }"
                  ><span class="custom__tag"
                    ><span>{{ option.customerNameWithID }}</span></span
                  ></template
                >
                <span slot="noResult">Oops! No result found.</span>
              </multiselect>
              <span
                class="error--text text--tiny"
                v-if="$v.form.$dirty && !$v.form.selectedCustomer.required"
              >
                Customer is required
              </span>
            </template>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="form__item">
                <label class="form__label">Product</label>
                <!--                <select class="select select&#45;&#45;lg" v-model="productTypeKey">-->
                <!--                  <option value="null" disabled selected v-if="!loading">-->
                <!--                    Select Product-->
                <!--                  </option>-->
                <!--                  <option value="null" disabled selected v-else>-->
                <!--                    Loading...-->
                <!--                  </option>-->
                <!--                  <template v-if="!loading">-->
                <!--                    <option-->
                <!--                      v-for="item in products"-->
                <!--                      :key="item.loanProductKey"-->
                <!--                      v-bind:value="item.loanProductKey"-->
                <!--                    >-->
                <!--                      {{ item.productName }}-->
                <!--                    </option>-->
                <!--                  </template>-->
                <!--                </select>-->
                <multiselect
                  v-model="productTypeKey"
                  id="loan_product"
                  label="productName"
                  track-by="loanProductKey"
                  placeholder="Select a product"
                  selectLabel=""
                  open-direction="bottom"
                  :options="products"
                  :searchable="true"
                  :internal-search="true"
                  :clear-on-select="false"
                  :close-on-select="true"
                  :options-limit="300"
                  :limit="7"
                  :max-height="800"
                  :loading="loading"
                  :preselect-first="false"
                >
                  <template slot="tag" slot-scope="{ option }"
                    ><span class="custom__tag"
                      ><span>{{ option.productName }}</span></span
                    ></template
                  >
                  <span slot="noResult">Oops! No result found.</span>
                </multiselect>
              </div>
            </div>
            <div class="col-6">
              <div class="form__item">
                <label class="form__label">Display Name</label>
                <input
                  type="text"
                  class="input form__input form__input--lg"
                  :class="[$v.form.$dirty && !$v.loanName.required]"
                  placeholder="Enter Display Name"
                  v-model="loanName"
                />
                <span
                  class="error--text text--tiny"
                  v-if="$v.form.$dirty && !$v.loanName.required"
                >
                  Loan name is required
                </span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="form__item">
                <label class="form__label">Product Type</label>
                <p class="text--black text--500 text--capital text--regular">
                  {{ loanProductType() }}
                </p>
              </div>
            </div>
            <div class="col-6">
              <div class="form__item">
                <label class="form__label">Product Description</label>
                <p class="text--black text--500 text--small">
                  {{ loanProductDescription() }}
                </p>
              </div>
            </div>
            <!-- <div class="col-6">
              <div class="form__item">
                <label class="form__label">Employer</label>
                <multiselect
                  v-model="employer"
                  id="employer"
                  label="name"
                  track-by="employerKey"
                  placeholder="Select an employer"
                  selectLabel=""
                  open-direction="bottom"
                  :options="sortedEmployers"
                  :searchable="true"
                  :internal-search="true"
                  :clear-on-select="false"
                  :close-on-select="true"
                  :options-limit="300"
                  :limit="7"
                  :max-height="800"
                  :loading="loading"
                  :preselect-first="false"
                  @input="updateEmployer"
                >
                  <template slot="tag" slot-scope="{ option }"
                    ><span class="custom__tag"
                      ><span>{{ option.name }}</span></span
                    ></template
                  >
                  <span slot="noResult">Oops! No result found.</span>
                </multiselect>
              </div>
            </div> -->
          </div>

          <ul id="accordion">
            <!--ACCOUNT TERMS-->
            <div class="modal__accordion">
              <li
                data-toggle="collapse"
                class="sidebar-right__item modal__accordion--toggle"
                data-target="#accountSettings"
                aria-expanded="false"
                aria-controls="accountSettings"
                ref="accountSettings"
              >
                <h5
                  class="w-100 mb-0 d-flex align-items-center justify-content-between"
                >
                  account terms
                  <span
                    class="text-info text--tiny"
                    v-if="
                      !$v.repaymentPeriodUnit.required ||
                      !$v.repaymentPeriodCount.required ||
                      !$v.loanAmount.required ||
                      !$v.repaymentInstallments.required ||
                      !$v.arrearsTolerancePeriod.required ||
                      !$v.arrearsToleranceAmount.required ||
                      !$v.interestRate.required ||
                      !$v.payOffRate.required
                    "
                  >
                    Fill all information below
                  </span>
                  <ion-icon name="chevron-down-outline"></ion-icon>
                </h5>
              </li>
              <div
                id="accountSettings"
                class="collapse"
                data-parent="#accordion"
              >
                <div class="pt-5">
                  <div class="row">
                    <div class="col-6">
                      <div class="form__item">
                        <label class="form__label">Loan Amount</label>
                        <input
                          type="text"
                          :min="minLoanAmount ? minLoanAmount : 0"
                          :max="maxLoanAmount ? maxLoanAmount : null"
                          class="input input--right form__input form__input--lg"
                          :class="{
                            'input-error':
                              !$v.loanAmount.required ||
                              numberIsValid(formattedLoanAmount),
                          }"
                          placeholder="Enter Loan Amount"
                          :value="formattedLoanAmount"
                          @input="numberIsValid(formattedLoanAmount)"
                          @blur="
                            controlValue(
                              'loanAmount',
                              loanAmount,
                              minLoanAmount,
                              maxLoanAmount,
                              $event
                            )
                          "
                        />
                        <small v-if="minLoanAmount > 0 || maxLoanAmount > 0"
                          >Min: {{ toNaira(minLoanAmount) }} Max:
                          {{ toNaira(maxLoanAmount) }}</small
                        >
                        <div class="error--text" v-if="!$v.loanAmount.required">
                          Loan Amount is required
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form__item">
                        <label class="form__label"
                          >Interest Rate {{ this.interestRateDisplay }}</label
                        >
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span
                              class="w-100 d-flex text--white text--600 text--medium align-items-center justify-content-center"
                            >
                              %
                            </span>
                          </div>
                          <input
                            type="text"
                            class="input input--right form__input form__input--lg"
                            :class="{
                              'input-error': !$v.interestRate.required,
                            }"
                            :min="loanMinInterestRate()"
                            :max="loanMaxInterestRate()"
                            placeholder="Enter interest rate per month"
                            v-model.number="interestRate"
                            @input="
                              controlValue(
                                'interestRate',
                                interestRate,
                                loanMinInterestRate(),
                                loanMaxInterestRate(),
                                $event
                              )
                            "
                          />
                        </div>
                        <small
                          v-if="
                            loanMinInterestRate() > 0 ||
                            loanMaxInterestRate() > 0
                          "
                          >Min: {{ loanMinInterestRate() }}% Max:
                          {{ loanMaxInterestRate() }}%</small
                        >
                        <div
                          class="error--text"
                          v-if="!$v.interestRate.required"
                        >
                          Interest Rate is required
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-4">
                    <label class="form__label">Over how many installment</label>
                    <input
                      type="text"
                      class="input input--right form__input form__input--lg"
                      v-model.number="repaymentInstallments"
                      :class="{
                        'input-error': !$v.repaymentInstallments.required,
                      }"
                      :min="minNumInstallments()"
                      :max="maxNumInstallments()"
                      :disabled="
                        controlEdit(
                          repaymentInstallments,
                          minNumInstallments(),
                          maxNumInstallments()
                        )
                      "
                      @input="
                        controlValue(
                          'repaymentInstallments',
                          repaymentInstallments,
                          minNumInstallments(),
                          maxNumInstallments(),
                          $event
                        )
                      "
                      placeholder="Enter how many installments"
                    />
                    <small
                      v-if="
                        minNumInstallments() > 0 && maxNumInstallments() > 0
                      "
                      >Min: {{ minNumInstallments() }} Max:
                      {{ maxNumInstallments() }}</small
                    >
                    <div
                      class="error--text"
                      v-if="!$v.repaymentInstallments.required"
                    >
                      Repayment Installments is required
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="form__item">
                      <label class="form__label"
                        >Arrears Tolerance Period</label
                      >
                      <input
                        type="text"
                        class="input input--right form__input form__input--lg"
                        :min="minTolerancePeriod()"
                        :max="maxTolerancePeriod()"
                        @input="
                          controlValue(
                            'arrearsTolerancePeriod',
                            arrearsTolerancePeriod,
                            minTolerancePeriod(),
                            maxTolerancePeriod(),
                            $event
                          )
                        "
                        placeholder="Enter number of days"
                        v-model.number="arrearsTolerancePeriod"
                      />
                      <div
                        class="error--text"
                        v-if="!$v.arrearsTolerancePeriod.required"
                      >
                        Arrears tolerance period is required
                      </div>
                      <div
                        class="error--text"
                        v-if="!uiProps.errors.arrearsTolerancePeriodError.valid"
                      >
                        Arrears tolerance amount must be between
                        {{ minTolerancePeriod() }} and
                        {{ maxTolerancePeriod() }}
                      </div>
                      <small
                        v-if="
                          maxTolerancePeriod() > 0 || minTolerancePeriod() > 0
                        "
                        >Min: {{ minTolerancePeriod() }} days Max:
                        {{ maxTolerancePeriod() }} days</small
                      >
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="form__item">
                      <label class="form__label"
                        >Arrears Tolerance Amount</label
                      >
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span
                            class="w-100 d-flex text--white text--600 text--medium align-items-center justify-content-center"
                          >
                            %
                          </span>
                        </div>
                        <input
                          type="text"
                          class="input input--right form__input form__input--lg"
                          placeholder="Enter Arrears Tolerance Amount"
                          v-model.number="arrearsToleranceAmount"
                          @input="
                            controlValue(
                              'arrearsToleranceAmount',
                              arrearsToleranceAmount,
                              minTolerancePercentageOfOutsatndingPrincipal(),
                              maxTolerancePercentageOfOutsatndingPrincipal(),
                              $event
                            )
                          "
                        />
                        <div
                          class="error--text"
                          v-if="!$v.arrearsToleranceAmount.required"
                        >
                          Arrears tolerance amount is required
                        </div>
                        <div
                          class="error--text"
                          v-if="
                            !uiProps.errors.arrearsToleranceAmountError.valid
                          "
                        >
                          Arrears tolerance amount must be between
                          {{ minTolerancePercentageOfOutsatndingPrincipal() }}
                          and
                          {{ maxTolerancePercentageOfOutsatndingPrincipal() }}
                        </div>
                        <small
                          v-if="
                            minTolerancePercentageOfOutsatndingPrincipal() >
                              0 ||
                            maxTolerancePercentageOfOutsatndingPrincipal() > 0
                          "
                        >
                          Min:
                          {{ minTolerancePercentageOfOutsatndingPrincipal() }}
                          Max:
                          {{ maxTolerancePercentageOfOutsatndingPrincipal() }}
                          (% of outstanding principal)</small
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-4">
                    <div class="form__item">
                      <label class="form__label">PayOff Rate</label>
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span
                            class="w-100 d-flex text--white text--600 text--medium align-items-center justify-content-center"
                          >
                            %
                          </span>
                        </div>
                        <input
                          type="text"
                          class="input input--right form__input form__input--lg"
                          :class="{
                            'input-error': !$v.payOffRate.required,
                          }"
                          placeholder="PayOff Rate"
                          v-model.number="payOffRate"
                          @input="
                            controlValue(
                              'payOffRate',
                              payOffRate,
                              null,
                              null,
                              $event
                            )
                          "
                          disabled
                        />
                        <div class="error--text" v-if="!$v.payOffRate.required">
                          PayOff rate is required
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-8 d-flex justify-content-around">
                    <div class="form__item flex-grow-1 p-2">
                      <label class="form__label"
                        >Repayments Are Made Every
                      </label>
                      <input
                        type="text"
                        class="input input--right form__input form__input--lg"
                        v-bind:placeholder="
                          'Enter number of ' + loanInterestType()
                        "
                        :class="{
                          'input-error': !$v.repaymentPeriodUnit.required,
                        }"
                        v-model.number="repaymentPeriodCount"
                        @input="
                          controlValue(
                            'repaymentPeriodCount',
                            repaymentPeriodCount,
                            null,
                            null,
                            $event
                          )
                        "
                        disabled
                      />
                      <div
                        class="error--text"
                        v-if="!$v.repaymentPeriodCount.required"
                      >
                        Repayment Count Unit is required
                      </div>
                    </div>
                    <div class="form__item p-2">
                      <label class="form__label">{{
                        loanInterestType()
                          ? loanInterestType()
                          : "Select a period"
                      }}</label>
                      <select
                        class="select select--lg"
                        v-model="repaymentPeriodUnit"
                        v-if="!loanInterestType()"
                      >
                        <option value="" disabled selected>Select</option>
                        <option
                          v-for="item in enums.repaymentAreMadeEvery"
                          :key="item.id"
                          v-bind:value="item.id"
                        >
                          {{ item.name }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <!-- <div class="col-6 d-flex justify-content-around">
                    <div
                      class="form__item flex-grow-1 p-2"
                    >
                      <label class="form__label"
                        >Collect principal every</label
                      >
                      <div
                        class="d-flex justify-content-between align-items-center"
                      >
                        <input
                          type="text"
                          class="input input--right form__input form__input--lg flex-grow-0"
                          :min="0"
                          v-model.number="defaultPrincipalRepaymentInterval"
                        />
                        <span class="flex-grow-1 p-2"> Month</span>
                      </div>
                    </div>
                  </div> -->

                  <!-- defaultPrincipalRepaymentInterval -->
                  <div class="col-6 d-flex justify-content-around">
                    <div
                      class="form__item flex-grow-1 p-2"
                      v-if="gracePeriodType > 0"
                    >
                      <label class="form__label"
                        >With a {{ loanGracePeriodTypeLabel() }} for</label
                      >
                      <div
                        class="d-flex justify-content-between align-items-center"
                      >
                        <input
                          type="text"
                          class="input input--right form__input form__input--lg flex-grow-0"
                          :min="0"
                          v-model.number="gracePeriod"
                          @input="
                            controlValue(
                              'gracePeriod',
                              gracePeriod,
                              null,
                              null,
                              $event
                            )
                          "
                        />
                        <span class="flex-grow-1 p-2">installments</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form__item">
                  <label class="form__label">Interest Calculation Method</label>
                  <p class="text--black text--600 text--capital text--regular">
                    {{ loanInterestCalculationMethod() }}
                  </p>
                </div>
              </div>
            </div>

            <!-- Charge Fees -->
            <div class="modal__accordion" v-if="loanHasFees">
              <li
                data-toggle="collapse"
                class="sidebar-right__item modal__accordion--toggle"
                data-target="#feesCharged"
                aria-expanded="false"
                aria-controls="disbursement"
                ref="disbursement"
              >
                <h5
                  class="w-100 mb-0 d-flex align-items-center justify-content-between"
                >
                  Charge Fees
                  <ion-icon name="chevron-down-outline"></ion-icon>
                </h5>
              </li>
              <div id="feesCharged" class="collapse" data-parent="#accordion">
                <div class="pt-5">
                  <!-- Required Loan Fee -->
                  <div
                    class="row"
                    v-for="fee in requiredLoanFees"
                    :key="fee.feeKey"
                  >
                    <div class="col-6">
                      <div class="form__item">
                        <select
                          class="select select--lg"
                          :value="fee.feeKey"
                          disabled
                        >
                          <option
                            v-for="item in requiredLoanFees"
                            :key="item.feeKey"
                            v-bind:value="item.feeKey"
                          >
                            {{ item.feeName }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form__item">
                        <input
                          type="text"
                          class="input input--right form__input form__input--lg"
                          :value="inputCurrencyFormat(fee.calculatedFeeAmount)"
                          disabled
                        />
                      </div>
                    </div>

                    <!-- Add fees button -->
                    <!-- v-if="!getFeeDifference" -->
                    <!-- <div class="form__item" v-if="!getFeeDifference">
                      <button
                        type="button"
                        class="button page__body__header__button button--grey text--capital"
                        @click="addLoanFees(fee)"
                      >
                        <ion-icon
                          name="add-outline"
                          class="text--regular"
                        ></ion-icon>
                        <span class="d-inline-flex ml-2">add</span>
                      </button>
                    </div> -->
                  </div>

                  <!-- Loan Fee not required -->
                  <div
                    class="row"
                    v-for="(fee, index) in newLoanFees"
                    :key="fee.feeKey"
                  >
                    <div class="col-6">
                      <div class="form__item" v-if="!fee.feeKey">
                        <select
                          class="select select--lg"
                          v-model="fee.feeKey"
                          @change="filterFeesFromLoan($event, index)"
                        >
                          <option value="" selected></option>
                          <option
                            v-for="item in filteredLoanFee"
                            :key="item.feeKey"
                            :value="item.feeKey"
                          >
                            {{ item.feeName }}
                          </option>
                        </select>
                      </div>
                      <div class="form__item" v-else>
                        <select
                          class="select select--lg"
                          v-model="fee.feeKey"
                          @change="filterFeesFromLoan($event, index)"
                        >
                          <option
                            v-for="newItem in fee.newFees"
                            :key="newItem.feeKey"
                            :value="newItem.feeKey"
                          >
                            {{ newItem.feeName }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-6" v-show="fee.feeKey">
                      <div class="form__item">
                        <input
                          type="text"
                          class="input input--right form__input form__input--lg"
                          :value="inputCurrencyFormat(fee.calculatedFeeAmount)"
                          disabled
                        />
                      </div>
                    </div>

                    <!-- Add fees button -->
                    <div class="form__item" v-if="!getFeeDifference">
                      <button
                        type="button"
                        class="button page__body__header__button button--grey text--capital"
                        @click="addLoanFees(fee)"
                      >
                        <ion-icon
                          name="add-outline"
                          class="text--regular"
                        ></ion-icon>
                        <span class="d-inline-flex ml-2">add</span>
                      </button>
                    </div>

                    <!-- Remove fees button -->
                    <div
                      class="form__item ml-3"
                      v-if="totalLoansNotRequired > 0"
                    >
                      <button
                        type="button"
                        class="button page__body__header__button button--grey text--capital"
                        @click="removeLoanFees(fee, index)"
                      >
                        <ion-icon
                          name="add-outline"
                          class="text--regular"
                        ></ion-icon>
                        <span class="d-inline-flex ml-2">remove</span>
                      </button>
                    </div>
                  </div>

                  <!-- Add new button -->
                  <div class="form__item" v-if="newLoanFees.length === 0">
                    <button
                      type="button"
                      class="button page__body__header__button button--grey text--capital"
                      @click="addLoanFees()"
                    >
                      <ion-icon
                        name="add-outline"
                        class="text--regular"
                      ></ion-icon>
                      <span class="d-inline-flex ml-2">add</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <!--DISBURSEMENT DETAILS-->
            <div class="modal__accordion">
              <li
                data-toggle="collapse"
                class="sidebar-right__item modal__accordion--toggle"
                data-target="#disbursement"
                aria-expanded="false"
                aria-controls="disbursement"
                ref="disbursement"
              >
                <h5
                  class="w-100 mb-0 d-flex align-items-center justify-content-between"
                >
                  Disbursement Details
                  <ion-icon name="chevron-down-outline"></ion-icon>
                </h5>
              </li>
              <div id="disbursement" class="collapse" data-parent="#accordion">
                <div class="pt-5">
                  <div class="row">
                    <div class="col-6">
                      <div class="form__item">
                        <label class="form__label"
                          >Anticipated Disbursement</label
                        >
                        <input
                          v-model="disbursementDetails.expectedDisbursementDate"
                          type="date"
                          class="input input--right form__input form__input--lg"
                          :class="{
                            'input-error':
                              !$v.disbursementDetails.expectedDisbursementDate
                                .required,
                          }"
                          placeholder="Enter number of days"
                          @input="calculateFirstRepaymentDate"
                        />
                        <div
                          class="error--text"
                          v-if="
                            !$v.disbursementDetails.expectedDisbursementDate
                              .required
                          "
                        >
                          Expected disbursement date is required
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form__item">
                        <label class="form__label">First Repayment Date</label>
                        <input
                          v-model="disbursementDetails.firstRepaymentDate"
                          type="date"
                          class="input input--right form__input form__input--lg"
                          :class="{
                            'input-error': scheduleGenerationError !== null,
                            'input-error':
                              !$v.disbursementDetails.firstRepaymentDate
                                .required,
                          }"
                          placeholder="Enter Arrears Tolerance Amount"
                        />
                        <div
                          class="error--text"
                          v-if="
                            !$v.disbursementDetails.firstRepaymentDate.required
                          "
                        >
                          First repayment date is required
                        </div>
                        <div
                          class="error--text"
                          v-if="scheduleGenerationError !== null"
                        >
                          {{ scheduleGenerationError }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6">
                      <div class="form__item">
                        <label class="form__label">Disbursement Bank</label>
                        <multiselect
                          :class="[
                            $v.form.$dirty && !$v.bank.bankKey.required
                              ? 'input-error'
                              : '',
                          ]"
                          v-model="bank"
                          id="bank"
                          label="name"
                          track-by="bankKey"
                          placeholder="Select a bank"
                          selectLabel="Select a bank"
                          open-direction="bottom"
                          :options="sortedBanks"
                          :searchable="true"
                          :internal-search="true"
                          :clear-on-select="false"
                          :close-on-select="true"
                          :options-limit="300"
                          :limit="7"
                          :max-height="800"
                          :loading="loading"
                          :preselect-first="false"
                        >
                          <template slot="tag" slot-scope="{ option }"
                            ><span class="custom__tag"
                              ><span>{{ option.productName }}</span></span
                            ></template
                          >
                          <span slot="noResult">Oops! No result found.</span>
                        </multiselect>
                        <div
                          class="error--text"
                          v-if="!$v.bank.bankKey.required"
                        >
                          Please select a bank account
                        </div>

                        <!-- <select
                          class="select select--lg"
                          v-model="bank"
                        >
                          <option value="null" disabled selected>__Select bank__</option>
                          <option :value="bank" v-for="(bank, index) in sortedBanks" :key="index">
                            {{ bank.name }}
                          </option>
                        </select> -->
                      </div>
                    </div>

                    <div class="col-6">
                      <div class="form__item">
                        <label class="form__label"
                          >Disbursement Account Number</label
                        >
                        <input
                          v-model="disbursementDetails.bankAccountNumber"
                          type="text"
                          maxlength="10"
                          class="input input--right form__input form__input--lg"
                          placeholder="Enter Disbursement Account Number"
                        />
                      </div>
                    </div>

                    <div class="col-6">
                      <div class="form__item">
                        <div class="form__action">
                          <button
                            id="save-changes"
                            type="button"
                            class="button form__button form__button--lg"
                            @click="validateAccountNumber"
                          >
                            {{ isValidating ? "Validating..." : "Validate" }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6">
                      <div class="form__item">
                        <label class="form__label"
                          >Disbursement Account Name</label
                        >
                        <input
                          v-model="disbursementDetails.bankAccountName"
                          type="text"
                          class="input input--right form__input form__input--lg"
                          placeholder=""
                          :disabled="!editBankDetails"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- SCHEDULE PREVIEW-->
            <div class="modal__accordion">
              <li
                data-toggle="collapse"
                class="sidebar-right__item modal__accordion--toggle"
                data-target="#schedule"
                aria-expanded="false"
                aria-controls="schedule"
              >
                <h5
                  class="w-100 mb-0 d-flex align-items-center justify-content-between"
                >
                  Schedule Preview
                  <ion-icon name="chevron-down-outline"></ion-icon>
                </h5>
              </li>
              <div id="schedule" class="collapse" data-parent="#accordion">
                <div class="pt-5">
                  <div>
                    <div class="form__item">
                      <label class="form__label">Amount to be disbursed</label>
                      <p
                        class="text--black text--600 text--capital text--regular"
                      >
                        {{ loanFees?.amountToDisbursed?.toFixed(2) }}
                      </p>
                      <label class="form__label"
                        >Amount used for schedule</label
                      >
                      <p
                        class="text--black text--600 text--capital text--regular"
                      >
                        {{ loanFees.amountToUseForSchedule }}
                      </p>
                      <label class="form__label">Expected Closure</label>
                      <p
                        class="text--black text--600 text--capital text--regular"
                      >
                        {{ expectedClosure() | moment("DD/MM/YYYY") }}
                      </p>
                      <div class="text-right mt-3">
                        <button
                          class="button button--grey text--600 text--black text--small"
                          @click="generateSchedule"
                          :disabled="loading"
                          type="button"
                        >
                          {{
                            generatingSchedule
                              ? "Calculating..."
                              : "Preview Schedule"
                          }}
                        </button>
                      </div>
                    </div>
                    <table
                      class="customtable w-100"
                      v-if="
                        !generatingSchedule &&
                        repayments &&
                        newRepayments &&
                        newRepayments.length
                      "
                    >
                      <thead>
                        <tr>
                          <th
                            class="table__header__text table__text text--capital text--tiny"
                            style="width: 5%"
                          >
                            #
                          </th>
                          <th
                            scope="col"
                            class="table__header__text table__text text--capital text--tiny"
                          >
                            Installment Date
                          </th>
                          <th
                            scope="col"
                            class="table__header__text table__text text--capital text--tiny"
                          >
                            Principal
                          </th>
                          <th
                            scope="col"
                            class="table__header__text table__text text--capital text--tiny"
                          >
                            Interest
                          </th>
                          <th
                            scope="col"
                            class="table__header__text table__text text--capital text--tiny"
                          >
                            Fees
                          </th>
                          <th
                            scope="col"
                            class="table__header__text table__text text--capital text--tiny"
                          >
                            Payment Due
                          </th>
                          <th
                            scope="col"
                            class="table__header__text table__text text--capital text--tiny"
                          >
                            Total Balance
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, index) in newRepayments" :key="index">
                          <td>
                            <span
                              class="table__body__text table__text text--capital text--tiny text--grey"
                              >{{ item.installment }}</span
                            >
                          </td>
                          <td>
                            <span
                              class="table__body__text table__text text--capital text--tiny text--grey"
                              >{{
                                item.installmentDate | moment("DD/MM/YYYY")
                              }}</span
                            >
                          </td>
                          <td>
                            <span
                              class="table__body__text table__text text--capital text--tiny text--grey"
                              >{{ toNaira(item.principal) }}</span
                            >
                          </td>
                          <td>
                            <span
                              class="table__body__text table__text text--capital text--tiny text--grey"
                              >{{ toNaira(item.interest) }}</span
                            >
                          </td>
                          <td>
                            <span
                              class="table__body__text table__text text--capital text--tiny text--grey"
                              >{{ toNaira(item.fee) }}</span
                            >
                          </td>
                          <td>
                            <span
                              class="table__body__text table__text text--capital text--tiny text--grey"
                              >{{ toNaira(item.paymentDue) }}</span
                            >
                          </td>
                          <td>
                            <span
                              class="table__body__text table__text text--capital text--tiny text--grey"
                            >
                              <!-- {{
                                toNaira(
                                  item.balance +
                                    item.principal +
                                    item.paymentDue +
                                    item.interest
                                )
                              }} -->
                              {{ toNaira(item.balance) }}
                            </span>
                          </td>
                        </tr>
                      </tbody>
                      <tbody>
                        <tr>
                          <td class="border-0"></td>
                          <td class="border-0">
                            <span
                              class="table__body__text table__text text--capital text--tiny text--success"
                            >
                              total
                            </span>
                          </td>
                          <td class="border-0">
                            <span
                              class="table__body__text table__text text--capital text--tiny text--success"
                            >
                              {{
                                repayments
                                  ? toNaira(repayments.totalPrincipal)
                                  : "_"
                              }}
                            </span>
                          </td>
                          <td class="border-0">
                            <span
                              class="table__body__text table__text text--capital text--tiny text--success"
                            >
                              {{
                                repayments
                                  ? toNaira(repayments.totalInterest)
                                  : "_"
                              }}
                            </span>
                          </td>
                          <td class="border-0">
                            <span
                              class="table__body__text table__text text--capital text--tiny text--success"
                            >
                              {{ repayments ? repayments.totalFee : "_" }}
                            </span>
                          </td>
                          <td class="border-0">
                            <span
                              class="table__body__text table__text text--capital text--tiny text--success"
                            >
                              {{
                                repayments
                                  ? toNaira(repayments.totalPaymentDue)
                                  : "_"
                              }}
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <!-- GUARANTORS-->
            <div class="modal__accordion" v-if="isGuarantorsEnabled()">
              <li
                data-toggle="collapse"
                class="sidebar-right__item modal__accordion--toggle"
                data-target="#guarantor"
                aria-expanded="false"
                aria-controls="guarantor"
              >
                <h5
                  class="w-100 mb-0 d-flex align-items-center justify-content-between"
                >
                  <div>
                    Guarantors
                    <span class="ml-3" v-if="guarantorsTotalAmount() > 0"
                      >({{ toNaira(guarantorsTotalAmount()) }})</span
                    >
                  </div>
                  <ion-icon name="chevron-down-outline"></ion-icon>
                </h5>
              </li>
              <div id="guarantor" class="collapse" data-parent="#accordion">
                <div class="pt-3">
                  <div
                    class="row py-3 my-5"
                    style="background: #fafafa"
                    v-for="(item, index) in guarantees"
                    :key="index"
                  >
                    <div class="col-12">
                      <div class="form__item">
                        <label class="form__label">Source</label>
                        <template>
                          <multiselect
                            label="customerName"
                            track-by="customerKey"
                            placeholder="Type to search for client by name"
                            open-direction="bottom"
                            :options="item.sourceList"
                            :value="item.source"
                            :multiple="false"
                            :searchable="true"
                            :loading="item.loadingSource"
                            :internal-search="true"
                            :clear-on-select="false"
                            :close-on-select="true"
                            :options-limit="300"
                            :limit="3"
                            :max-height="600"
                            :show-no-results="false"
                            :hide-selected="true"
                            @search-change="searchCustomers($event, index)"
                            @select="
                              updateGuarantorField($event, index, 'source')
                            "
                          >
                            <template slot="tag" slot-scope="{ option }"
                              ><span class="custom__tag"
                                ><span>{{ option.customerName }}</span></span
                              >
                            </template>
                            <span slot="noResult">Oops! No result found.</span>
                          </multiselect>
                        </template>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form__item">
                        <label class="form__label">Account Number</label>
                        <select
                          class="select select--lg"
                          v-model="item.account"
                        >
                          <option
                            :value="null"
                            disabled
                            selected
                            v-if="!item.loadingAccounts && item.source"
                          >
                            Select Account
                          </option>
                          <option
                            value="null"
                            disabled
                            selected
                            v-if="item.loadingAccounts"
                          >
                            Loading...
                          </option>
                          <option
                            :value="null"
                            disabled
                            selected
                            v-if="!item.loadingAccounts && !item.source"
                          >
                            Select a source first
                          </option>
                          <template v-if="!item.loadingAccounts">
                            <option
                              v-for="(item2, index2) in item.accountList"
                              :key="index2"
                              :value="item2.accountKey"
                            >
                              {{ item2.accountName }}
                            </option>
                          </template>
                        </select>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form__item">
                        <label class="form__label">Amount</label>
                        <input
                          :value="item.amount"
                          type="text"
                          class="input input--right form__input form__input--lg"
                          @input="updateGuarantorField($event, index, 'amount')"
                        />
                      </div>
                    </div>
                    <div class="col-12">
                      <button
                        type="button"
                        class="button ml-auto page__body__header__button button--cancel text--capital"
                        @click="removeGuarantor(index)"
                      >
                        <span class="d-inline-flex">delete</span>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="mt-3">
                  <button
                    type="button"
                    class="button page__body__header__button button--grey text--capital"
                    @click="pushGuarantorForm"
                  >
                    <ion-icon
                      name="add-outline"
                      class="text--regular"
                    ></ion-icon>
                    <span class="d-inline-flex ml-2">add guarantor</span>
                  </button>
                </div>
              </div>
            </div>

            <!-- COLLATERAL ASSET-->
            <div class="modal__accordion" v-if="isCollateralEnabled()">
              <li
                data-toggle="collapse"
                class="sidebar-right__item modal__accordion--toggle"
                data-target="#collateral"
                aria-expanded="false"
                aria-controls="collateral"
              >
                <h5
                  class="w-100 mb-0 d-flex align-items-center justify-content-between"
                >
                  <div>
                    Collateral Asset
                    <span class="ml-3" v-if="collateralTotalAmount() > 0"
                      >({{ toNaira(collateralTotalAmount()) }})</span
                    >
                  </div>
                  <ion-icon name="chevron-down-outline"></ion-icon>
                </h5>
              </li>
              <div id="collateral" class="collapse" data-parent="#accordion">
                <div class="pt-5">
                  <!-- <div class="row">
                    <div class="col-4">
                      <div class="form__item mb-3">
                        <label class="form__label">Name</label>
                        <input
                          v-model="collateralModel.assetName"
                          type="text"
                          class="input input--right form__input form__input--lg"
                          :class="{
                            'input-error':
                              collateralModel.errorAssetName == true,
                          }"
                          @input="validateCollateral"
                        />
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="form__item mb-3">
                        <label class="form__label">Description</label>
                        <input
                          v-model="collateralModel.description"
                          type="text"
                          class="input input--right form__input form__input--lg"
                          :class="{
                            'input-error':
                              collateralModel.errorDescription == true,
                          }"
                        />
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="form__item mb-3">
                        <label class="form__label">Amount</label>
                        <input
                          v-model="collateralModel.amount"
                          type="text"
                          class="input input--right form__input form__input--lg"
                          :class="{
                            'input-error': collateralModel.errorAmount == true,
                          }"
                          @input="
                            updateCollateralInputField($event, index, 'amount')
                          "
                        />
                      </div>
                    </div>
                    <div class="col-12 text-right">
                      <button
                        type="button"
                        class="
                          button
                          page__body__header__button
                          button--grey
                          text--capital
                          float-right
                        "
                        @click="addCollateral"
                      >
                        <ion-icon
                          name="add-outline"
                          class="text--regular"
                        ></ion-icon>
                        <span class="d-inline-flex ml-2">add</span>
                      </button>
                    </div>
                  </div> -->
                  <div
                    class="row py-3 my-5"
                    style="background: #fafafa"
                    v-for="(asset, index) in collateralAssets"
                    :key="index"
                  >
                    <div class="col-4">
                      <div class="form__item">
                        <label class="form__label">Name</label>
                        <input
                          type="text"
                          :value="asset.assetName"
                          class="input input--right form__input form__input--lg"
                          @input="
                            updateCollateralInputField(
                              $event,
                              index,
                              'assetName'
                            )
                          "
                        />
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="form__item">
                        <label class="form__label">Description</label>
                        <input
                          type="text"
                          :value="asset.description"
                          class="input input--right form__input form__input--lg"
                          @input="
                            updateCollateralInputField(
                              $event,
                              index,
                              'description'
                            )
                          "
                        />
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="form__item">
                        <label class="form__label">Amount</label>
                        <input
                          type="text"
                          :value="asset.amount"
                          class="input input--right form__input form__input--lg"
                          @input="
                            updateCollateralInputField($event, index, 'amount')
                          "
                        />
                      </div>
                    </div>
                    <div class="col-12">
                      <button
                        type="button"
                        class="button ml-auto page__body__header__button button--cancel text--capital"
                        @click="removeCollateralAsset(index)"
                      >
                        <span class="d-inline-flex">delete</span>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="col-12 text-right">
                  <button
                    type="button"
                    class="button page__body__header__button button--grey text--capital float-right"
                    @click="addCollateral"
                  >
                    <ion-icon
                      name="add-outline"
                      class="text--regular"
                    ></ion-icon>
                    <span class="d-inline-flex ml-2">add collateral</span>
                  </button>
                </div>
              </div>
            </div>

            <!-- CUSTOM FIELDS -->
            <div
              class="modal__accordion"
              v-for="item in uiProps.customFieldSets"
              :key="item.customFieldSetKey"
            >
              <li
                data-toggle="collapse"
                class="sidebar-right__item modal__accordion--toggle"
                :data-target="'#dynamic_' + item.customFieldSetKey"
                aria-expanded="false"
                :aria-controls="'dynamic_' + item.customFieldSetKey"
              >
                <h5
                  class="w-100 mb-0 d-flex align-items-center justify-content-between text-uppercase"
                >
                  {{ item.customFieldSetName }}
                  <ion-icon name="chevron-down-outline"></ion-icon>
                </h5>
              </li>
              <div
                :id="'dynamic_' + item.customFieldSetKey"
                class="collapse"
                data-parent="#accordion"
              >
                <div class="pt-5">
                  <!-- <div class="text-right mb-3">
                    <button
                      type="button"
                      @click="openCustomFieldModal(item.customFieldSetKey)"
                      v-if="canAddCustomField(item.customFieldSetKey)"
                      class="
                        d-flex
                        align-items-center
                        ml-auto
                        button button--grey
                        text--600 text--black text--small text--capital
                      "
                    >
                      add fields
                      <ion-icon
                        name="add-outline"
                        class="text--600 text--medium ml-2"
                      ></ion-icon>
                    </button>
                  </div> -->
                  <div class="row">
                    <template v-for="item2 in item.customFields">
                      <div
                        class="col-6"
                        :key="item2.customFieldKey"
                        v-show="isDefault(item2)"
                      >
                        <!--TEXT/NUMBER-->
                        <div
                          class="form__item"
                          v-if="
                            getFieldType(item2.dataType) === 'text' ||
                            getFieldType(item2.dataType) === 'number'
                          "
                        >
                          <label class="form__label"
                            >{{ item2.customFieldName }}
                          </label>
                          <div class="d-flex align-items-center">
                            <input
                              type="text"
                              class="input input--right form__input form__input--lg"
                              :id="item2.customFieldKey"
                              :ref="item2.customFieldKey"
                              @input="
                                updateCustomField(item2.customFieldKey, $event)
                              "
                            />
                            <!-- <button
                              type="button"
                              class="ml5 button button--cancel text--capital"
                              @click="
                                updateCustomFieldDefaultState(
                                  item.customFieldSetKey,
                                  item2.customFieldKey,
                                  false
                                )
                              "
                            >
                              <span class="d-inline-flex">delete</span>
                            </button> -->
                          </div>
                        </div>
                        <!--DATE-->
                        <div
                          class="form__item"
                          v-else-if="getFieldType(item2.dataType) === 'date'"
                        >
                          <label class="form__label">{{
                            item2.customFieldName
                          }}</label>
                          <div class="d-flex align-items-center">
                            <input
                              type="date"
                              :id="item2.customFieldKey"
                              :ref="item2.customFieldKey"
                              class="input input--right form__input form__input--lg"
                              @input="
                                updateCustomField(item2.customFieldKey, $event)
                              "
                            />
                            <!-- <button
                              type="button"
                              class="ml5 button button--cancel text--capital"
                              @click="
                                updateCustomFieldDefaultState(
                                  item.customFieldSetKey,
                                  item2.customFieldKey,
                                  false
                                )
                              "
                            >
                              <span class="d-inline-flex">delete</span>
                            </button> -->
                          </div>
                        </div>
                        <!--CHECKBOX-->
                        <div
                          class="form__item"
                          v-else-if="
                            getFieldType(item2.dataType) === 'checkbox'
                          "
                        >
                          <label class="form__label">{{
                            item2.customFieldName
                          }}</label>
                          <div class="d-flex align-items-center">
                            <label class="form__label has-checkbox">
                              <input
                                type="checkbox"
                                class="input input--right form__input form__input--lg"
                                :id="item2.customFieldKey"
                                :ref="item2.customFieldKey"
                                @input="
                                  updateCustomField(
                                    item2.customFieldKey,
                                    $event
                                  )
                                "
                              />
                              <span class="checkmark"></span>
                            </label>
                            <!-- <button
                              type="button"
                              class="ml5 button button--cancel text--capital"
                              @click="
                                updateCustomFieldDefaultState(
                                  item.customFieldSetKey,
                                  item2.customFieldKey,
                                  false
                                )
                              "
                            >
                              <span class="d-inline-flex">delete</span>
                            </button> -->
                          </div>
                        </div>
                        <!--SELECT-->
                        <div
                          class="form__item"
                          v-else-if="getFieldType(item2.dataType) === 'select'"
                        >
                          <label class="form__label">{{
                            item2.customFieldName
                          }}</label>
                          <div class="d-flex align-items-center">
                            <select
                              class="select select--lg"
                              :id="item2.customFieldKey"
                              :ref="item2.customFieldKey"
                              @change="
                                updateCustomField(item2.customFieldKey, $event)
                              "
                            >
                              <option value="null" disabled selected>
                                Select {{ item2.customFieldName }}
                              </option>
                              <option
                                v-for="itemSelection in item2.customFieldSelections"
                                :key="itemSelection.customFieldSelectionKey"
                                v-bind:value="itemSelection.value"
                              >
                                {{ itemSelection.value }}
                              </option>
                            </select>
                            <!-- <button
                              type="button"
                              class="ml5 button button--cancel text--capital"
                              @click="
                                updateCustomFieldDefaultState(
                                  item.customFieldSetKey,
                                  item2.customFieldKey,
                                  false
                                )
                              "
                            >
                              <span class="d-inline-flex">delete</span>
                            </button> -->
                          </div>
                        </div>

                        <!-- multiselect -->
                        <div
                          class="form__item"
                          v-else-if="
                            getFieldType(item2.dataType) === 'user link'
                          "
                        >
                          <label class="form__label">{{
                            item2.fieldName
                          }}</label>
                          <div class="d-flex align-items-center">
                            <multiselect
                              v-model="item2.customFieldValue"
                              :id="item2.customFieldKey"
                              :ref="item2.customFieldKey"
                              label="name"
                              track-by="userKey"
                              placeholder="Type to search"
                              open-direction="bottom"
                              :options="sortedUsers"
                              :multiple="false"
                              :searchable="true"
                              :internal-search="true"
                              :clear-on-select="false"
                              :close-on-select="true"
                              :options-limit="300"
                              :limit="3"
                              :max-height="800"
                              :show-no-results="false"
                              :hide-selected="true"
                              @selected="
                                updateCustomField(item2.fieldKey, $event)
                              "
                            >
                              <template
                                slot="tag"
                                slot-scope="{ option, remove }"
                              >
                                <span class="custom__tag">
                                  <span>{{ option.name }}</span>
                                  <span
                                    class="custom__remove"
                                    @click="remove(option)"
                                    >❌</span
                                  >
                                </span>
                              </template>
                            </multiselect>

                            <!-- <button
                              type="button"
                              class="ml5 button button--cancel text--capital"
                              @click="
                                updateCustomFieldDefaultState(
                                  item.fieldSetKey,
                                  item2.fieldKey,
                                  false
                                )
                              "
                            >
                              <span class="d-inline-flex">delete</span>
                            </button> -->
                          </div>
                        </div>

                        <!-- multiselect customer -->
                        <div
                          class="form__item"
                          v-else-if="
                            getFieldType(item2.dataType) === 'customer link'
                          "
                        >
                          <label class="form__label">{{
                            item2.fieldName
                          }}</label>
                          <div class="d-flex align-items-center">
                            <multiselect
                              v-model="item2.customFieldValue"
                              :id="item2.customFieldKey"
                              :ref="item2.customFieldKey"
                              label="accountFullName"
                              track-by="customerKey"
                              placeholder="Type to search"
                              open-direction="bottom"
                              :options="sortedCustomers"
                              :multiple="false"
                              :searchable="true"
                              :internal-search="true"
                              :clear-on-select="false"
                              :close-on-select="true"
                              :options-limit="300"
                              :limit="3"
                              :max-height="800"
                              :show-no-results="false"
                              :hide-selected="true"
                              @selected="
                                updateCustomField(item2.fieldKey, $event)
                              "
                            >
                              <template
                                slot="tag"
                                slot-scope="{ option, remove }"
                              >
                                <span class="custom__tag">
                                  <span>{{ option.accountFullName }}</span>
                                  <span
                                    class="custom__remove"
                                    @click="remove(option)"
                                    >❌</span
                                  >
                                </span>
                              </template>
                            </multiselect>

                            <!-- <button
                              type="button"
                              class="ml5 button button--cancel text--capital"
                              @click="
                                updateCustomFieldDefaultState(
                                  item.fieldSetKey,
                                  item2.fieldKey,
                                  false
                                )
                              "
                            >
                              <span class="d-inline-flex">delete</span>
                            </button> -->
                          </div>
                        </div>

                        <!-- multiselect group -->
                        <div
                          class="form__item"
                          v-else-if="
                            getFieldType(item2.dataType) === 'group link'
                          "
                        >
                          <label class="form__label">{{
                            item2.fieldName
                          }}</label>
                          <div class="d-flex align-items-center">
                            <multiselect
                              v-model="item2.customFieldValue"
                              :id="item2.customFieldKey"
                              :ref="item2.customFieldKey"
                              label="groupName"
                              track-by="groupKey"
                              placeholder="Type to search"
                              open-direction="bottom"
                              :options="sortedGroups"
                              :multiple="false"
                              :searchable="true"
                              :internal-search="true"
                              :clear-on-select="false"
                              :close-on-select="true"
                              :options-limit="300"
                              :limit="3"
                              :max-height="800"
                              :show-no-results="false"
                              :hide-selected="true"
                              @selected="
                                updateCustomField(item2.fieldKey, $event)
                              "
                            >
                              <template
                                slot="tag"
                                slot-scope="{ option, remove }"
                              >
                                <span class="custom__tag">
                                  <span>{{ option.groupName }}</span>
                                  <span
                                    class="custom__remove"
                                    @click="remove(option)"
                                    >❌</span
                                  >
                                </span>
                              </template>
                            </multiselect>

                            <!-- <button
                              type="button"
                              class="ml5 button button--cancel text--capital"
                              @click="
                                updateCustomFieldDefaultState(
                                  item.fieldSetKey,
                                  item2.fieldKey,
                                  false
                                )
                              "
                            >
                              <span class="d-inline-flex">delete</span>
                            </button> -->
                          </div>
                        </div>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </div>
            <!-- ASSOCIATION-->
            <div class="modal__accordion">
              <li
                data-toggle="collapse"
                class="sidebar-right__item modal__accordion--toggle"
                data-target="#association"
                aria-expanded="false"
                aria-controls="association"
              >
                <h5
                  class="w-100 mb-0 d-flex align-items-center justify-content-between"
                >
                  Association
                  <span
                    class="error--text text--tiny"
                    v-if="$v.form.$dirty && !$v.form.assignedBranchKey.required"
                  >
                    Association is required
                  </span>
                  <ion-icon name="chevron-down-outline"></ion-icon>
                </h5>
              </li>
              <div id="association" class="collapse" data-parent="#accordion">
                <div class="pt-5">
                  <div class="row">
                    <div class="col-12">
                      <div class="form__item">
                        <label class="form__label">Branches</label>
                        <select
                          class="select select--lg"
                          required
                          v-model="form.assignedBranchKey"
                          @change="getAccountOfficer"
                          :class="[
                            $v.form.$dirty &&
                            !$v.form.assignedBranchKey.required
                              ? 'input-error'
                              : '',
                          ]"
                        >
                          <option value="null" disabled selected>
                            Select Association Branches
                          </option>
                          <option
                            v-for="item in branchList"
                            :key="item.branchKey"
                            v-bind:value="item.branchKey"
                          >
                            {{ item.branchName }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12">
                      <div class="form__item">
                        <label class="form__label">Account Officer</label>
                        <select
                          class="select select--lg"
                          required
                          v-model="form.assignedUserKey"
                        >
                          <option value="null" disabled selected>
                            Select Account Officer
                          </option>
                          <option
                            v-for="item in accountOfficers"
                            :key="item.userKey"
                            :value="item.userKey"
                          >
                            {{ item.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--ACCOUNT NOTES-->
            <div class="modal__accordion">
              <li
                data-toggle="collapse"
                class="sidebar-right__item modal__accordion--toggle"
                data-target="#decriptions"
                aria-expanded="false"
                aria-controls="decriptions"
              >
                <h5
                  class="w-100 mb-0 d-flex align-items-center justify-content-between"
                >
                  Account Notes
                  <ion-icon name="chevron-down-outline"></ion-icon>
                </h5>
              </li>
              <div id="decriptions" class="collapse" data-parent="#accordion">
                <div class="pt-5">
                  <div class="form__item">
                    <textarea
                      cols="5"
                      rows="5"
                      class="input form__input"
                      v-model="notes"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </ul>
          <div class="form__item">
            <div class="form__action">
              <button
                id="save-changes"
                type="button"
                class="button form__button form__button--lg float-right"
                @click="confirmSave()"
              >
                {{ process ? "Saving..." : "Create Account" }}
              </button>
              <router-link
                to="/loan/accounts"
                class="button button--cancel form__button form__button--lg float-left"
                >Cancel</router-link
              >
            </div>
          </div>
        </form>
      </div>
    </div>
  </main>
</template>

<script>
import { CreateLoanAccountInitialState } from "./config_modules/initialStates";
import { mapState } from "vuex";
import { required } from "vuelidate/lib/validators";
import LoanApiService from "@/core/services/api.loan.service";
import GeneralApiService from "@/core/services/general.service";
import AccessApiService from "@/core/services/access.service";
import branchService from "@/core/services/organization-service/branches.service";
import { GET_ALL_LOAN_PRODUCTS } from "@/core/services/store/types";
import Multiselect from "vue-multiselect";
import AddCustomFieldModal from "./components/addCustomFieldModal";
import { GET_ACTIVE_BANKS } from "@/core/services/store/banking.module";
import ApiService from "../../../core/services/api.service";
import currencyMixin from "@/mixins/currencyMixin";
import moment from "moment";

export default {
  name: "CreateLoanAccountPage",
  mixins: [currencyMixin],
  components: {
    Multiselect,
    AddCustomFieldModal,
  },
  props: {
    show: Boolean,
    close: Function,
    customer: {
      required: false,
      type: Object,
      default: null,
    },
  },
  data: function () {
    return CreateLoanAccountInitialState();
  },
  validations: {
    loanAmount: { required },
    loanName: { required },
    interestRate: { required },
    repaymentPeriodUnit: { required },
    repaymentPeriodCount: { required },
    repaymentInstallments: { required },
    arrearsTolerancePeriod: { required },
    arrearsToleranceAmount: { required },
    payOffRate: { required },
    form: {
      assignedBranchKey: { required },
      selectedCustomer: { required },
    },
    disbursementDetails: {
      expectedDisbursementDate: { required },
      firstRepaymentDate: { required },
    },
    bank: {
      bankKey: { required },
    },
  },
  methods: {
    addLoanFees(loan) {
      if (loan)
        return this.newLoanFees.push({
          feeKey: "",
          calculatedFeeAmount: 0,
          feeName: loan.feeName,
        });
      return this.newLoanFees.push({
        feeKey: "",
        calculatedFeeAmount: 0,
        feeName: "",
      });
    },
    removeLoanFees(loan, index) {
      const feeWithIdIndex = this.newFees.findIndex(
        (obj) => obj === loan.feeKey
      );
      this.newFees.splice(feeWithIdIndex, 1);
      this.getLoansNotRequired(this.newFees);
      this.newLoanFees.splice(index, 1);
    },
    getLoansNotRequired(value) {
      if (value) {
        const filArray = this.loanFeesNotRequired.filter(
          (fee) => fee.isRequired !== true && !value.includes(fee.feeKey)
        );
        this.filteredLoanFee = filArray;
      } else {
        this.filteredLoanFee = this.loanFees.loanProductFees.filter(
          (fee) => fee.isRequired !== true
        );
      }
    },
    storeLoansNotRequireFee(value, index) {
      const newValue = this.loanFeesNotRequired.filter(
        (fee) => fee.isRequired !== true && fee.feeKey === value
      );
      this.newLoanFees[index].newFees = newValue;
      this.newLoanFees[index].calculatedFeeAmount =
        this.newLoanFees[index].newFees[0].calculatedFeeAmount;
    },
    filterFeesFromLoan(e, index) {
      const value = e.target.value;
      this.storeLoansNotRequireFee(value, index);
      this.newFees[index] = value;
      this.getLoansNotRequired(this.newFees, index);
    },
    updateEmployer() {
      this.employerKey = this.employer.employerKey;
    },
    getAccountOfficer() {
      AccessApiService.get(
        `ProleanUser/GetBranchClientOfficer?branchKey=${this.form.assignedBranchKey}`
      ).then((resp) => {
        this.accountOfficers = resp.data.data;
      });
    },
    calculateFirstRepaymentDate() {
      const date = this.disbursementDetails.expectedDisbursementDate;
      const dateNo = this.repaymentPeriodCount;
      let dated = "";
      if (this.loanInterestType() === "Days") {
        dated = moment(date, "YYYY-MM-DD").add("days", dateNo);
        let day = dated.format("DD");
        let month = dated.format("MM");
        let year = dated.format("YYYY");
        return (this.disbursementDetails.firstRepaymentDate = `${year}-${month}-${day}`);
      }
      if (this.loanInterestType() === "Weeks") {
        const noOfDays = dateNo * 7;
        dated = moment(date, "YYYY-MM-DD").add("days", noOfDays);
        let day = dated.format("DD");
        let month = dated.format("MM");
        let year = dated.format("YYYY");
        return (this.disbursementDetails.firstRepaymentDate = `${year}-${month}-${day}`);
      }
      if (this.loanInterestType() === "Months") {
        dated = moment(date, "YYYY-MM-DD").add("months", dateNo);
        let day = dated.format("DD");
        let month = dated.format("MM");
        let year = dated.format("YYYY");
        return (this.disbursementDetails.firstRepaymentDate = `${year}-${month}-${day}`);
      }
      dated = moment(date, "YYYY-MM-DD").add("years", dateNo);
      let day = dated.format("DD");
      let month = dated.format("MM");
      let year = dated.format("YYYY");
      this.disbursementDetails.firstRepaymentDate = `${year}-${month}-${day}`;
    },
    goToLoanAccount() {
      this.$router.push({ path: "/loan/accounts" });
    },
    getAllBanks() {
      this.$store.dispatch(GET_ACTIVE_BANKS);
    },
    numberIsValid(amount) {
      if (!this.isValidNumberInput(amount)) return true;
      return false;
    },
    fomartAndValidateLoanAmount(amount) {
      const amountValue = amount.target.value;
      const amtPaid = this.convertFigureToNumber(amountValue);
      const amountInputted = this.inputCurrencyFormat(amtPaid);

      if (this.validateDepositAmount(amountValue)) {
        this.loanAmount = amtPaid;
        this.formattedLoanAmount = amountInputted;
        this.getNewProductFee();
        return;
      }
      this.loanAmount = this.loanAmount;
      this.formattedLoanAmount = "";
    },
    validateAccountNumber() {
      this.disbursementDetails.bankAccountName = "";
      this.editBankDetails = false;
      try {
        this.isValidating = true;
        let account = {
          accountNumber: this.disbursementDetails.bankAccountNumber,
          accountBank: this.bank.flutterWaveBankCode,
        };
        setTimeout(
          () =>
            LoanApiService.post("LoanAccount/verify-account", account)
              .then((res) => res.data)
              .then((res) => {
                if (res.status) {
                  this.disbursementDetails.bankAccountName =
                    res.data.data.account_name;
                  this.isValidating = false;
                }
                if (res.status == 400) {
                  this.alertError("Account not found!");
                }
              })
              .catch((error) => {
                this.isValidating = false;
                this.editBankDetails = true;
                this.disbursementDetails.bankAccountName = this.nameError;
                this.alertError(
                  error.response.data.message || "Account not found!"
                );
              }),
          1500
        );
      } catch (e) {
        this.alertError(e.response.title);
      }
    },
    validateLoanAmount() {
      if (this.loanAmount < this.minLoanAmount) {
        return true;
      } else if (this.loanAmount > this.maxLoanAmount) {
        // return this.alertError('Loan amount can not be larger than loan amount specified');
        return true;
      }
      return false;
    },
    validate: function () {
      if (this.isGuarantorsEnabled) {
        if (this.guarantees.length < 1) {
          this.uiProps.errors.guarantees.valid = false;
          this.uiProps.errors.guarantees.message =
            "This Loan Product requires Guarantee";
          return false;
        } else {
          this.uiProps.errors.guarantees.valid = true;
          this.uiProps.errors.guarantees.message = "";
        }
      }
      if (this.isCollateralEnabled) {
        if (this.collateralAssets.length < 1) {
          this.uiProps.errors.collateral.valid = false;
          this.uiProps.errors.collateral.message =
            "This Loan Product requires Collateral";
          return false;
        } else {
          this.uiProps.errors.collateral.valid = true;
          this.uiProps.errors.collateral.message = "";
        }
      }
      if (
        this.arrearsToleranceAmount <
        this.minTolerancePercentageOfOutsatndingPrincipal()
      ) {
        this.uiProps.errors.arrearsToleranceAmountError.valid = false;
        this.uiProps.errors.arrearsToleranceAmountError.message =
          "Arrears tolerance amount can not be less than the minimum tolerance amount";
        return false;
      }
      if (
        this.arrearsToleranceAmount >
        this.maxTolerancePercentageOfOutsatndingPrincipal()
      ) {
        this.uiProps.errors.arrearsToleranceAmountError.valid = false;
        this.uiProps.errors.arrearsToleranceAmountError.message =
          "Arrears tolerance amount can not be greater than the maximum tolerance amount";
        return false;
      } else {
        this.uiProps.errors.arrearsToleranceAmountError.valid = true;
        this.uiProps.errors.arrearsToleranceAmountError.message = "";
      }
      if (this.arrearsTolerancePeriod < this.minTolerancePeriod()) {
        this.uiProps.errors.arrearsTolerancePeriodError.valid = false;
        this.uiProps.errors.arrearsTolerancePeriodError.message =
          "Arrears tolerance period can not be less than the minimum tolerance period";
        return false;
      }
      if (this.arrearsTolerancePeriod > this.maxTolerancePeriod()) {
        this.uiProps.errors.arrearsTolerancePeriodError.valid = false;
        this.uiProps.errors.arrearsTolerancePeriodError.message =
          "Arrears tolerance period can not be greater than the maximum tolerance period";
        return false;
      } else {
        this.uiProps.errors.arrearsTolerancePeriodError.valid = true;
        this.uiProps.errors.arrearsTolerancePeriodError.message = "";
      }
      if (this.loanAmount < this.minLoanAmount) {
        this.uiProps.errors.loanAmount.valid = false;
        this.uiProps.errors.loanAmount.message =
          "This Loan cannnot be less than " + this.minLoanAmount;
        return false;
      }
      if (this.loanAmount > this.maxLoanAmount) {
        this.uiProps.errors.loanAmount.valid = false;
        this.uiProps.errors.loanAmount.message =
          "This Loan cannnot be more than " + this.maxLoanAmount;
        return false;
      } else {
        this.uiProps.errors.loanAmount.valid = true;
        this.uiProps.errors.loanAmount.message = "";
      }
      return true;
    },
    alertSuccess(description) {
      this.alert.status = "success";
      this.alert.title = "Success";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    alertWarning(description) {
      this.alert.status = "warn";
      this.alert.title = "Warning";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    alertError(description) {
      this.alert.status = "fail";
      this.alert.title = "Error";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    openCustomFieldModal(id) {
      this.$refs.cfmodal.click();
      this.uiProps.customFieldSet = this.uiProps.customFieldSets.find(
        (item) => item.customFieldSetKey == id
      );
    },
    updateCustomFieldDefaultState(
      customFieldSetId,
      customFieldsId,
      state = true
    ) {
      const customFieldSetIndex = this.uiProps.customFieldSets.findIndex(
        (item) => item.customFieldSetKey == customFieldSetId
      );
      if (customFieldSetIndex !== -1) {
        const cloneModel = JSON.parse(
          JSON.stringify(this.uiProps.customFieldSets)
        );
        const customFieldIndex = this.uiProps.customFieldSets[
          customFieldSetIndex
        ].customFields.findIndex(
          (item) => item.customFieldKey == customFieldsId
        );
        if (customFieldIndex !== -1) {
          cloneModel[customFieldSetIndex].customFields[
            customFieldIndex
          ].isDefault = state;
          this.uiProps.customFieldSets = cloneModel;
        }
      }
    },
    isDefault(field) {
      let check = field.customFieldLinks.find(
        (link) => link.entityLinkedKey === this.productTypeKey.loanProductKey
      );
      if (check) {
        return check.isDefault;
      }
      return false;
    },
    canAddCustomField(id) {
      const can = this.uiProps.customFieldSets
        .find((item) => item.customFieldSetKey === id)
        .customFields.findIndex((item) => item.isDefault === false);
      if (can !== -1) {
        return true;
      } else {
        return false;
      }
    },
    toNaira(value) {
      const val = new Intl.NumberFormat().format(value);
      return "₦" + val;
    },
    getFieldType(type) {
      if (type.indexOf("Number") !== -1) {
        return "number";
      } else if (type.indexOf("FreeText") !== -1) {
        return "text";
      } else if (type.indexOf("Selection") !== -1) {
        return "select";
      } else if (type.indexOf("Checkbox") !== -1) {
        return "checkbox";
      } else if (type.indexOf("Date") !== -1) {
        return "date";
      } else if (type.indexOf("User link") !== -1) {
        return "user link";
      } else if (type.indexOf("Customer link") !== -1) {
        return "customer link";
      } else if (type.indexOf("Group link") !== -1) {
        return "group link";
      }
      return "text";
    },
    getAllUsers() {
      this.allUsers = [];
      try {
        const userModel = {
          pageNumber: 1,
          pageSize: 1000000,
          branchKey: "",
          status: true,
          filters: [],
        };
        AccessApiService.post(
          "ProleanUser/GetUsersWithoutPermission",
          userModel
        ).then((resp) => {
          this.allUsers = resp.data.data;
        });
      } catch (e) {
        const error = e.response.data.message;
        this.$emit("alertError", error);
      }
    },
    getAllGroups() {
      this.allActiveGroups = [];
      try {
        const userModel = {
          pageIndex: 1,
          pageSize: 1000000,
          filter: "",
          filters: [],
        };
        GeneralApiService.post("Group/Groups", userModel).then((resp) => {
          this.allActiveGroups = resp.data.data.items;
        });
      } catch (e) {
        const error = e.response.data.message;
        this.$emit("alertError", error);
      }
    },
    controlValue(prop, val, min, max, e) {
      let transVal = e.target.value;
      if (prop === "loanAmount") {
        this.fomartAndValidateLoanAmount(e);
        //const val = e.target.value.replace(/([^0-9.])/g, "");
        //if (val < min && min) {
        //return this.validateAmounts = true;
        //}
        //if (val > min && min) {
        //return this.validateAmounts = true;
        //}
        //this.validateAmounts = false;
      }
      if (prop === "arrearsToleranceAmount") {
        const val = e.target.value.replace(/([^0-9.])/g, "");
        if (val < min) {
          this.alertError("Value cannot be less than " + min);
          this.uiProps.errors.arrearsToleranceAmountError.valid = false;
          return;
        }
        if (val > max) {
          this.alertError("Value cannot be more than " + max);
          this.uiProps.errors.arrearsToleranceAmountError.valid = false;
          return;
        }
        this.uiProps.errors.arrearsToleranceAmountError.valid = true;
        this.uiProps.errors.arrearsToleranceAmountError.message = "";
      }
      if (prop === "arrearsTolerancePeriod") {
        const val = e.target.value.replace(/([^0-9.])/g, "");
        if (val < min) {
          this.alertError("Value cannot be less than " + min);
          this.uiProps.errors.arrearsTolerancePeriodError.valid = false;
          return;
        }
        if (val > max) {
          this.alertError("Value cannot be more than " + max);
          this.uiProps.errors.arrearsTolerancePeriodError.valid = false;
          return;
        }
        this.uiProps.errors.arrearsTolerancePeriodError.valid = true;
        this.uiProps.errors.arrearsTolerancePeriodError.message = "";
      }
      if (e) {
        if (/[^0-9.]/g.test(e.target.value)) {
          transVal = e.target.value.replace(/([^0-9.])/g, "");
          if (transVal < min && min) {
            // this[prop] = min;
            //this.alertError("Value cannot be less than " + min);
            //this.validateAmounts = true;
            // e.target.value = min;
          } else if (transVal > max && max) {
            // this[prop] = max;
            // e.target.value = max;
            //this.alertError("Value cannot be more than " + max);
            //this.validateAmounts = true;
          } else if (parseInt(transVal) < 0) {
            this[prop] = "";
            e.target.value = "";
            //this.validateAmounts = true;
          } else {
            this[prop] = transVal;
            e.target.value = transVal;
            //this.validateAmounts = false;
          }
        } else {
          if (transVal < min && min) {
            // this[prop] = min;
            //this.alertError("Value cannot be less than " + min);
            //this.validateAmounts = true;
            // e.target.value = min;
          } else if (transVal > max && max) {
            // this[prop] = max;
            //this.alertError("Value cannot be more than " + max);
            //this.validateAmounts = true;
            // e.target.value = max;
          } else if (parseInt(transVal) < 0) {
            this[prop] = "";
            e.target.value = "";
            //this.validateAmounts = true;
          } else {
            this[prop] = transVal;
            e.target.value = transVal;
            //this.validateAmounts = false;
          }
        }
      }
    },
    controlEdit(d, min, max) {
      if (d == min && min && d == max && max) {
        return true;
      }
      return false;
    },
    getLoanProducts() {
      this.$store.dispatch(GET_ALL_LOAN_PRODUCTS);
    },
    searchCustomersSingle(search) {
      const self = this;
      if (search.length) {
        self.loadingCustomers = true;
        GeneralApiService.get("Customer/search/" + search)
          .then((res) => res.data.data.items)
          .then((res) => {
            self.loadingCustomers = false;
            self.customers = res;
          })
          .catch(() => {
            self.loadingCustomers = false;
          });
      }
    },
    searchCustomers(search, index) {
      const self = this;
      if (search.length) {
        self.guarantees[index].loadingSource = true;
        GeneralApiService.get("Customer/search/" + search)
          .then((res) => res.data.data.items)
          .then((res) => {
            self.guarantees[index].loadingSource = false;
            self.guarantees[index].sourceList = res;
          })
          .catch(() => {
            self.guarantees[index].loadingSource = false;
          });
      }
    },
    resetWindow: function () {
      Object.assign(this.$data, CreateLoanAccountInitialState());
    },
    confirmSave() {
      this.$v.form.$touch();

      if (this.$v.$invalid) {
        this.alertError("Some Required Fields are empty");
        if (this.$refs.accountSettings.attributes[2].value == "false") {
          this.$refs.accountSettings.click();
        }
      } else if (!this.uiProps.errors.arrearsToleranceAmountError.valid) {
        this.alertError(
          "Arrears tolerance amount must be between" +
            this.minTolerancePercentageOfOutsatndingPrincipal() +
            " and " +
            this.maxTolerancePercentageOfOutsatndingPrincipal()
        );
      } else if (!this.uiProps.errors.arrearsTolerancePeriodError.valid) {
        this.alertError(
          "Arrears tolerance amount must be between" +
            this.minTolerancePeriod() +
            " and " +
            this.maxTolerancePeriod()
        );
      } else {
        if (!this.confirm) {
          this.confirm = true;
        } else {
          this.confirm = false;
          this.process = false;
        }
      }
    },
    generateSchedule() {
      this.scheduleGenerationError = null;
      const errMsg =
        "Repayment Installments is required to preview the repayment schedule";
      if (!this.repaymentInstallments) {
        this.alertError(errMsg);
        this.scheduleGenerationError = errMsg;
        this.$refs.accountSettings.click();
        return;
      }
      if (
        !this.disbursementDetails.firstRepaymentDate &&
        this.disbursementDetails.expectedDisbursementDate
      ) {
        this.scheduleGenerationError = errMsg;
        this.alertError(errMsg);
        this.$refs.disbursement.click();
        return;
      }

      const p = {
        loanProductKey: this.productTypeKey.loanProductKey,
        loanAmount: this.loanAmount,
        formattedLoanAmount: this.inputCurrencyFormat(this.loanAmount),
        repaymentInstallments: this.repaymentInstallments,
        interestRate: this.interestRate,
        disbursmentDate: this.disbursementDetails.expectedDisbursementDate,
        firstRepaymentDate: this.disbursementDetails.firstRepaymentDate,
        repaymentAreMadeEveryEnum: this.repaymentReschedulingMethod,
        repaymentScheduleMethod: this.repaymentReschedulingMethod,
        gracePeriod: Number(this.productTypeKey.gracePeriodType),
        gracePeriodValue: this.gracePeriod,
        loanAccountProductFeesList: this.newLoanFees.map((fee) => fee.feeKey),
        //defaultPrincipalRepaymentInterval: this.defaultPrincipalRepaymentInterval,
      };

      //validate first repayment date
      const self = this;
      this.generatingSchedule = true;
      LoanApiService.patch("LoanAccount/getrepaymentschedule", p)
        .then((res) => res.data)
        .then((res) => {
          self.generatingSchedule = false;
          self.loanFees.amountToDisbursed = res.data.amountToDisbursed;
          self.loanFees.amountToUseForSchedule =
            res.data.amountToUseForSchedule;
          if (!res.status) {
            self.scheduleGenerationError = res.message;
            self.$refs.disbursement.click();
          } else {
            self.repayments = res.data;
          }
        })
        .catch((e) => {
          self.generatingSchedule = false;
          try {
            if (!e.response.data.status) {
              if (e.response.data.message.indexOf("First repayment") !== -1) {
                self.scheduleGenerationError = e.response.data.message;
                if (self.$refs.disbursement.attributes[2].value == "false") {
                  self.$refs.disbursement.click();
                }
              } else {
                self.alertError(e.response.data.message);
              }
            }
          } catch (e) {
            self.alertError(e.message);
          }
        });
    },
    save: function () {
      this.$v.form.$touch();
      if (!this.$v.$invalid) {
        this.process = true;
        let copyOfPayload = JSON.parse(JSON.stringify(this.$data));
        let newCollateralAssets = [];
        let newGuarantorListing = [];
        //
        // transform
        //

        // custom field validation
        let isValidate = false;
        if (this.uiProps.customFieldSets.length > 0) {
          const loanProductKey = copyOfPayload.productTypeKey.loanProductKey;
          this.uiProps.customFieldSets.forEach((formSet) => {
            formSet.customFields.forEach((form) => {
              let key = form.customFieldKey;
              let value = this.$refs[key][0].value;

              if (
                ["customer link", "group link", "user link"].includes(
                  form.dataType.toLowerCase()
                ) &&
                value.length == 0
              ) {
                value = "";
              }
              if (form.dataType.toLowerCase() === "checkbox") {
                value = this.$refs[key][0].checked;
              }

              let required = form.customFieldLinks.find(
                (item) => item.entityLinkedKey === loanProductKey
              );
              if (!value && required && required.isRequired) {
                this.alertWarning(
                  `Custom field value for ${form.customFieldName} is required.`
                );
                isValidate = true;
                return;
              }
            });
          });
        }
        if (isValidate) {
          this.confirm = false;
          this.process = false;
          return;
        }

        //guarantees
        // const guaranteesTransformed = [];
        if (this.isGuarantorsEnabled) {
          if (this.guarantees.length) {
            copyOfPayload.guarantees.map((item) => {
              // const obj = {
              //   guarantorKey: item.source,
              //   guarantorType: "CLIENT",
              //   amount: item.amount,
              //   savingsAccountKey: item.account,
              //   type: "GUARANTOR",
              // };
              return {
                guarantorKey: item.source.customerKey,
                guarantorType: "GUARANTOR",
                amount: item.amount,
                savingsAccountKey: item.account,
                type: "GUARANTOR",
              };
              // guaranteesTransformed.push(obj);
            });
            const guarantor = copyOfPayload.guarantees;
            const guarantorListing = guarantor.map((e) => e.actualObject);
            newGuarantorListing = Array.prototype.concat.apply(
              [],
              guarantorListing
            );
          }
        } else {
          copyOfPayload.guarantees = [];
        }
        //assets
        if (this.isCollateralEnabled) {
          copyOfPayload.collateralAssets.map((item) => ({
            description: item.description,
            guarantorType: "ASSET",
            amount: item.amount,
            type: "ASSET",
          }));
          copyOfPayload.assets = copyOfPayload.collateralAssets;
          const collateralAssets = copyOfPayload.collateralAssets;
          const collateralAssetsListing = collateralAssets.map(
            (e) => e.actualObject
          );
          newCollateralAssets = Array.prototype.concat.apply(
            [],
            collateralAssetsListing
          );
        } else {
          copyOfPayload.collateralAssets = [];
          copyOfPayload.assets = [];
        }
        copyOfPayload.guarantees = [
          ...newGuarantorListing,
          ...newCollateralAssets,
        ];
        // if (this.isGuarantorsEnabled || this.isCollateralEnabled) {
        //   copyOfPayload.guarantees = guaranteesTransformed;
        // } else {
        //   copyOfPayload.guarantees = [];
        // }
        if (
          !copyOfPayload.disbursementDetails.expectedDisbursementDate ||
          !copyOfPayload.disbursementDetails.firstRepaymentDate
        ) {
          copyOfPayload.disbursementDetails.expectedDisbursementDate = null;
          copyOfPayload.disbursementDetails.firstRepaymentDate = null;
        }
        copyOfPayload.productFeeList = this.newLoanFees.map(
          (fee) => fee.feeKey
        );
        const { productTypeKey, ...newCopyOfPayload } = copyOfPayload;
        let mergedData = {
          ...newCopyOfPayload,
          ...this.form,
          productTypeKey: productTypeKey.loanProductKey,
        };
        mergedData.accountArrearsSettings.tolerancePercentageOfOutstandingPrincipal =
          mergedData.arrearsToleranceAmount;
        mergedData.accountHolderKey = mergedData.selectedCustomer.customerKey;
        //clean up payload
        delete mergedData.form;
        delete mergedData.branchList;
        delete mergedData.customers;
        delete mergedData.scheduleReady;
        delete mergedData.loadingSchedule;
        delete mergedData.loadingCustomers;
        delete mergedData.loadingGuarantorAccounts;
        delete mergedData.generatingSchedule;
        delete mergedData.guarantorAccounts;
        delete mergedData.selectedCustomer;
        delete mergedData.alert;
        delete mergedData.guaranteeModel;
        delete mergedData.collateralAssets;
        delete mergedData.collateralModel;
        delete mergedData.scheduleGenerationError;
        delete mergedData.whatPage;
        delete mergedData.showInactiveFees;
        delete mergedData.confirm;
        delete mergedData.process;
        delete mergedData.repayments;
        delete mergedData.minLoanAmount;
        delete mergedData.maxLoanAmount;
        delete mergedData.customer;
        delete mergedData.loanProduct;
        delete mergedData.uiProps;
        delete mergedData.employer;
        delete mergedData.allEmployers;
        delete mergedData.allActiveGroups;
        delete mergedData.allUsers;
        if (Object.keys(this.bank).length === 0) {
          this.alertError("Please select a disbursement bank");
        } else {
          mergedData.disbursementDetails.bankKey = this.bank.bankKey;
          mergedData.disbursementDetails.bankAccountName =
            this.disbursementDetails.bankAccountName;
        }
        delete mergedData.bank;
        delete mergedData.loanFees;
        delete mergedData.newLoanFees;
        delete mergedData.filteredLoanFee;
        delete mergedData.newFees;
        //if(this.validateAmounts) {
        //this.process = false;
        //this.confirm = false;
        //this.alertWarning('Loan Amount can not be greater or lesser than the maximum values specified');
        //return;
        //}

        LoanApiService.post("LoanAccount/addloanaccount", mergedData)
          .then((res) => res.data)
          .then((res) => {
            this.confirm = false;
            this.process = false;
            if (res.status) {
              this.alertSuccess(res.message);
              this.$router.push({
                path: `/loan/accounts/${res.data.loanAccountKey}`,
              });
            } else {
              this.alertWarning(res.message);
            }
          })
          .catch((e) => {
            this.confirm = false;
            try {
              if (e.response.status == 409) {
                this.process = false;
                this.alertWarning(e.response.data.message);
                return;
              }
              this.process = false;
              this.alertError(e.response.data.message);
            } catch (e) {
              this.alertError(
                "An unexpected Error occurred: Kindly confirm you are connected to the internet"
              );
            }
          });
      } else {
        this.confirm = false;
        this.process = false;
        this.alertError("Some Required Fields are empty");
        if (this.$refs.accountSettings.attributes[2].value == "false") {
          this.$refs.accountSettings.click();
        }
      }
    },
    listBranches() {
      this.loadingBranchList = true;
      return branchService.get({
        branchName: null,
        branchState: "Active",
        pageIndex: 1,
        pageSize: 100,
      });
    },
    validateStringValue(val, length = 4) {
      if (val == null || val == undefined) {
        return true;
      }
      if (typeof val == "string") {
        if (val.length === 0) {
          return true;
        } else if (val.length < length) {
          this.alertError("Value must be more than " + length);
          return false;
        } else {
          return true;
        }
      }
      return false;
    },
    validateNumberValue(val) {
      if (val == null || val == undefined) {
        return true;
      }
      if (!/^[0-9]+$/.test(val)) {
        this.alertError("Value must contain numbers only");
        return false;
      }
      if (val < 1) {
        this.alertError("Value must be more than 1");
        return false;
      } else {
        return true;
      }
    },
    addCollateral() {
      //validate form
      this.collateralModel.errorAmount = false;
      this.collateralModel.errorDescription = false;
      this.collateralModel.errorAssetName = false;
      if (!this.validateStringValue(this.collateralModel.description)) {
        this.collateralModel.errorDescription = true;
        return false;
      }
      if (!this.validateStringValue(this.collateralModel.assetName, 1)) {
        this.collateralModel.errorAssetName = true;
        return false;
      }
      if (!this.validateNumberValue(this.collateralModel.amount)) {
        this.collateralModel.errorAmount = true;
        return false;
      }
      const cloneModel = JSON.parse(JSON.stringify(this.collateralModel));
      this.collateralAssets = [...this.collateralAssets, cloneModel];
      this.collateralModel.description = null;
      this.collateralModel.amount = null;
      this.collateralModel.assetName = null;
    },
    validateCollateral() {
      //validate form
      this.collateralModel.errorAmount = false;
      this.collateralModel.errorAssetName = false;
      this.collateralModel.errorDescription = false;
      if (!this.validateStringValue(this.collateralModel.assetName, 1)) {
        this.collateralModel.errorAssetName = true;
        return false;
      }
      if (!this.validateStringValue(this.collateralModel.description)) {
        this.collateralModel.errorDescription = true;
        return false;
      }
      if (!this.validateNumberValue(this.collateralModel.amount)) {
        this.collateralModel.errorAmount = true;
        return false;
      }
    },
    updateCollateralInputField(e, index, prop) {
      const actualObject = {
        savingsAccountKey: "",
        type: "ASSET",
        guarantorKey: "",
        guarantorType: "ASSET",
        amount: "",
        description: "",
        assetName: "",
      };
      const copyOfCollateralAssets = [...this.collateralAssets];
      copyOfCollateralAssets[index][prop] = e.target.value;
      copyOfCollateralAssets[index].actualObject = actualObject;
      copyOfCollateralAssets[index].actualObject.assetName =
        copyOfCollateralAssets[index].assetName;
      copyOfCollateralAssets[index].actualObject.description =
        copyOfCollateralAssets[index].description;
      copyOfCollateralAssets[index].actualObject.amount =
        copyOfCollateralAssets[index].amount;
      this.collateralAssets = copyOfCollateralAssets;
    },
    updateGuarantorField(e, index, prop) {
      //if its an action from the customers dropdown
      if (prop === "source") {
        const copyOfGuarantors = [...this.guarantees];
        const actualObject = {
          savingsAccountKey: "",
          type: "GUARANTOR",
          guarantorKey: "",
          guarantorType: "GUARANTOR",
          amount: "",
          description: "",
          assetName: "",
        };
        copyOfGuarantors[index][prop] = e;
        //this.guarantees = copyOfGuarantors;
        copyOfGuarantors[index].actualObject = actualObject;
        copyOfGuarantors[index].actualObject.guarantorKey = e.customerKey;
        this.sources = [...copyOfGuarantors];

        //search for the selected customer accounts
        if (e.hasOwnProperty("customerKey")) {
          if (e.customerKey.length) {
            copyOfGuarantors[index].loadingAccounts = true;
            ApiService.get(
              "DepositAccount/GetDepositAccountByCustomer/" + e.customerKey
            )
              .then((res) => {
                if (res) {
                  copyOfGuarantors[index].accountList = res.data.data;
                }
                copyOfGuarantors[index].loadingAccounts = false;
              })
              .catch(() => {
                copyOfGuarantors[index].loadingAccounts = false;
              });
          }
        }
      } else if (prop === "amount") {
        const copyOfGuarantors = [...this.guarantees];
        copyOfGuarantors[index][prop] = e.target.value;
        copyOfGuarantors[index].actualObject.amount = e.target.value;
        copyOfGuarantors[index].actualObject.savingsAccountKey =
          copyOfGuarantors[index].account;
        this.sources = copyOfGuarantors;
      } else if (prop === "account") {
        const copyOfGuarantors = [...this.guarantees];
        copyOfGuarantors[index].actualObject.savingsAccountKey =
          e.target.options[e.target.selectedIndex].value;
        copyOfGuarantors[index].actualObject.savingsAccountKey =
          copyOfGuarantors[index].account;
        copyOfGuarantors[index].actualObject.savingsAccountKey = "this";
        this.guarantees = copyOfGuarantors;
      }
    },
    removeCollateralAsset(index) {
      this.collateralAssets.splice(index, 1);
    },
    removeGuarantor(index) {
      this.guarantees.splice(index, 1);
    },
    loanProductDescription() {
      return this.loanProduct ? this.loanProduct.productDescription : "";
    },
    loanRepaymentScheduleMethod() {
      return this.loanProduct ? this.loanProduct.repaymentScheduleMethod : null;
    },
    loanMinInterestRate() {
      return this.loanProduct
        ? this.loanProduct.adminInterestProductSettings
          ? this.loanProduct.adminInterestProductSettings.minInterestRate
          : null
        : null;
    },
    loanMaxInterestRate() {
      return this.loanProduct
        ? this.loanProduct.adminInterestProductSettings
          ? this.loanProduct.adminInterestProductSettings.maxInterestRate
          : null
        : null;
    },
    maxNumInstallments() {
      return this.loanProduct
        ? this.loanProduct.maxNumInstallments
          ? this.loanProduct.maxNumInstallments
          : null
        : null;
    },
    minNumInstallments() {
      return this.loanProduct
        ? this.loanProduct.minNumInstallments
          ? this.loanProduct.minNumInstallments
          : null
        : null;
    },
    minFirstRepaymentDueDateOffset() {
      return this.loanProduct
        ? this.loanProduct.minFirstRepaymentDueDateOffset
          ? this.loanProduct.minFirstRepaymentDueDateOffset
          : null
        : null;
    },
    maxFirstRepaymentDueDateOffset() {
      return this.loanProduct
        ? this.loanProduct.maxFirstRepaymentDueDateOffset
          ? this.loanProduct.maxFirstRepaymentDueDateOffset
          : null
        : null;
    },
    maxTolerancePercentageOfOutsatndingPrincipal() {
      return this.productTypeKey
        ? this.productTypeKey.adminArrearsResponseModel
          ? this.productTypeKey.adminArrearsResponseModel
              .maxTolerancePercentageOfOutstandingPrincipal
          : null
        : null;
    },
    minTolerancePercentageOfOutsatndingPrincipal() {
      return this.productTypeKey
        ? this.productTypeKey.adminArrearsResponseModel
          ? this.productTypeKey.adminArrearsResponseModel
              .minTolerancePercentageOfOutstandingPrincipal
          : null
        : null;
    },
    maxTolerancePeriod() {
      return this.productTypeKey
        ? this.productTypeKey.adminArrearsResponseModel
          ? this.productTypeKey.adminArrearsResponseModel.maxTolerancePeriod
          : null
        : null;
    },
    minTolerancePeriod() {
      return this.productTypeKey
        ? this.productTypeKey.adminArrearsResponseModel
          ? this.productTypeKey.adminArrearsResponseModel.minTolerancePeriod
          : null
        : null;
    },
    loanProductType() {
      return this.loanProduct
        ? this.loanProduct.loanProductType.replace(/_/g, " ")
        : "";
    },
    loanGracePeriodTypeId() {
      return this.loanProduct ? this.loanProduct.gracePeriodType > 0 : null;
    },
    isGuarantorsEnabled() {
      return this.loanProduct
        ? this.loanProduct.adminProductSecuritySetting.isGuarantorsEnabled > 0
        : null;
    },
    isCollateralEnabled() {
      return this.loanProduct
        ? this.loanProduct.adminProductSecuritySetting.isCollateralEnabled > 0
        : null;
    },
    loanGracePeriodTypeLabel() {
      if (this.loanProduct) {
        const hh = this.enums.gracePeriod.find(
          (h) => h.id == this.loanProduct.gracePeriodType
        );
        return hh.name;
      }
    },
    loanInterestType() {
      if (this.loanProduct) {
        const hh = this.enums.repaymentAreMadeEvery.find(
          (h) => h.id == this.loanProduct.repaymentScheduleMethod
        );
        return hh.name;
      }
    },
    accountRecipient() {
      if (this.customer) {
        if (this.customer.customerFullname) {
          return this.customer.customerFullname;
        }
      }
      return "";
    },
    repaymentAreMadeEvery() {
      const self = this;
      return this.enums.repaymentAreMadeEvery.filter((unit) => {
        if (unit.id == 0 || unit.id == 1) return true;
        if ((unit.id == 2 || unit.id == 3) && self.daysInYear == 2) {
          return true;
        } else {
          return false;
        }
      });
    },
    anticipatedDisbursementDateIsReq() {
      return !this.disbursementDetails.expectedDisbursementDate;
    },
    expectedClosure() {
      if (this.repayments) {
        if (Array.isArray(this.repayments)) {
          if (this.repayments.length) {
            return this.repayments[this.repayments.length - 1].installmentDate;
          }
        }
      }
      return null;
    },
    guarantorsTotalAmount: function () {
      let total = 0;
      this.guarantees.map((item) => {
        total += parseInt(item.amount);
      });
      return total;
    },
    collateralTotalAmount: function () {
      let total = 0;
      this.collateralAssets.map((item) => {
        total += parseInt(item.amount);
      });
      return total;
    },
    selectGuarantor() {
      this.loadingGuarantorAccounts = true;
      const self = this;
      ApiService.get(
        "DepositAccount/GetDepositAccountByCustomer/9212e96888494c83806c2abaa6dff3a5"
      )
        .then((res) => {
          if (res) {
            self.guarantorAccounts = res.data.data;
          }
          self.loadingGuarantorAccounts = false;
        })
        .catch(() => {
          self.loadingGuarantorAccounts = false;
        });
    },
    setSelectedProduct(id) {
      this.getFieldSets(id);
      LoanApiService.get(
        "LoanProduct/getloanproduct/" + this.productTypeKey.loanProductKey
      ).then((res) => {
        this.selectedLoanProduct = res.data.data;
        const p = this.selectedLoanProduct;
        //console.log(p);
        this.loanProduct = p;
        this.productTypeKey = id;
        this.loanName = this.form.selectedCustomer.customerNameWithID;
        this.defaultPrincipalRepaymentInterval =
          p.defaultPrincipalRepaymentInterval;
        this.loanAmount =
          p.minLoanAmount > p.defaultLoanAmount
            ? p.minLoanAmount
            : p.defaultLoanAmount;
        this.payOffRate = p.payOffRate ? p.payOffRate : 0;
        this.formattedLoanAmount = this.inputCurrencyFormat(this.loanAmount);
        this.maxLoanAmount = p.maxLoanAmount ? p.maxLoanAmount : null;
        this.minLoanAmount = p.minLoanAmount ? p.minLoanAmount : null;
        this.gracePeriod = p.defaultGracePeriod ? p.defaultGracePeriod : 0;
        this.gracePeriodType = p.gracePeriodType ? p.gracePeriodType : 0;
        this.repaymentPeriodCount = p.repaymentPeriodUnit
          ? p.repaymentPeriodUnit
          : 0;
        this.repaymentPeriodUnit = p.repaymentReschedulingMethod
          ? p.repaymentReschedulingMethod == "NA"
            ? 0
            : 0
          : 0;
        this.repaymentInstallments = p.defaultNumInstallments
          ? p.defaultNumInstallments
          : null;
        this.repaymentReschedulingMethod = p.repaymentScheduleMethod
          ? p.repaymentScheduleMethod
          : null;
        this.repaymentScheduleMethod = p.repaymentScheduleMethod
          ? p.repaymentScheduleMethod
          : null;
        this.interestCalculationMethod = p.interestCalculationMethod
          ? p.interestCalculationMethod
          : null;
        this.interestRate = p.adminInterestProductSettings
          ? p.adminInterestProductSettings.defaultInterestRate
            ? p.adminInterestProductSettings.defaultInterestRate
            : null
          : null;
        this.interestType = p.interestType ? p.interestType : null;
        this.arrearsTolerancePeriod = p.adminArrearsResponseModel
          ? p.adminArrearsResponseModel.defaultTolerancePeriod
            ? p.adminArrearsResponseModel.defaultTolerancePeriod
            : null
          : null;
        this.arrearsToleranceAmount = p.adminArrearsResponseModel
          ? p.adminArrearsResponseModel
              .defaultTolerancePercentageOfOutstandingPrincipal
            ? p.adminArrearsResponseModel
                .defaultTolerancePercentageOfOutstandingPrincipal
            : null
          : null;
        this.accountArrearsSettings.tolerancePercentageOfOutstandingPrincipal =
          p.adminArrearsResponseModel
            ? p.adminArrearsResponseModel
                .defaultTolerancePercentageOfOutstandingPrincipal
              ? p.adminArrearsResponseModel
                  .defaultTolerancePercentageOfOutstandingPrincipal
              : null
            : null;
        this.accountArrearsSettings.monthlyToleranceDay =
          p.adminArrearsResponseModel
            ? p.adminArrearsResponseModel.monthlyToleranceDsay
              ? p.adminArrearsResponseModel.monthlyToleranceDsay
              : 0
            : 0;
        this.defaultFirstRepaymentDueDateOffset = p.adminArrearsResponseModel
          ? p.adminArrearsResponseModel.defaultInterestRate
            ? p.adminArrearsResponseModel
                .defaultTolerancePercentageOfOutstandingPrincipal
            : null
          : null;
      });
      // this.$v.form.$reset()
    },
    pushGuarantorForm() {
      const cloneModel = JSON.parse(JSON.stringify(this.guaranteeModel));
      this.guarantees = [...this.guarantees, cloneModel];
    },
    getFieldSets(usage) {
      this.uiProps.loadingCustomFieldSets = true;
      const p = {
        customFieldSetType: "LoanAccounts",
        entityLinkedKey: usage.productTypeKey || usage.loanProductKey,
      };
      GeneralApiService.post("CustomFields/FieldSets/FieldSetType", p)
        .then((res) => res.data.data)
        .then((res) => {
          this.uiProps.customFieldSets = res.items;
          this.uiProps.loadingCustomFieldSets = false;
        })
        .catch(() => {
          this.uiProps.loadingCustomFieldSets = false;
        });
    },
    loanInterestCalculationMethod() {
      if (this.loanProduct) {
        const hh = this.enums.interestCalculationMethod.find(
          (h) => h.id == this.loanProduct.interestCalculationMethod
        );
        return hh.name;
      } else {
        return "NA";
      }
    },
    updateCustomField(fieldId, event) {
      const fieldIndex = this.form.customInformation.findIndex(
        (item) => item.customFieldID == fieldId
      );
      if (fieldIndex !== -1) {
        this.form.customInformation[fieldIndex] = {
          value: event.target.value,
          customFieldID: fieldId,
        };
      } else {
        this.form.customInformation.push({
          value: event.target.value,
          customFieldID: fieldId,
        });
      }
    },
    setDefaultCustomer() {
      const { customer_id } = this.$route.query;

      if (customer_id) {
        this.loadingCustomers = true;
        GeneralApiService.get(`Customer/${customer_id}`)
          .then((res) => res.data.data)
          .then((res) => {
            this.loadingCustomers = false;
            this.form.selectedCustomer = res;
          })
          .catch(() => {
            this.loadingCustomers = false;
          });
      }
    },
    setDefaultProduct() {
      const { product_id } = this.$route.query;

      if (product_id) {
        LoanApiService.get(`LoanProduct/getloanproduct/${product_id}`)
          .then((res) => res.data.data)
          .then((res) => {
            this.loadingCustomers = false;
            this.productTypeKey = res;
          })
          .catch(() => {
            this.loadingCustomers = false;
          });
      }
    },
    getAllCustomers() {
      //const paged = {
      //pageIndex: 1,
      //pageSize: 99999,
      //filters: [],
      //customerTypeKey: null,
      //customerStatus: null,
      //};

      GeneralApiService.get("Customer/getallcustomers")
        //.then((res) => res.data.data)
        .then((res) => {
          this.loadingCustomers = false;
          this.customers = res.data.data;
        })
        .catch(() => {
          this.loadingCustomers = false;
        });
    },
    getAllEmployers() {
      const paged = {
        pageIndex: 1,
        pageSize: 99999,
      };

      GeneralApiService.post("Employer/Employers", paged)
        .then((res) => res.data.data.items)
        .then((res) => {
          this.allEmployers = res;
        });
    },
    // selectProduct(c){
    //   console.log(c)
    // }
    getProductFees() {
      LoanApiService.get(
        "LoanProduct/getloanproductfees/" +
          this.productTypeKey.loanProductKey +
          "/" +
          this.productTypeKey.defaultLoanAmount +
          "/" +
          this.productTypeKey.defaultNumInstallments
      )
        .then((res) => {
          this.loanFees = res.data.data;
          this.getLoansNotRequired();
        })
        .catch((error) => {
          this.alertError(error?.message);
        });
    },
    getNewProductFee() {
      setTimeout(() => {
        LoanApiService.get(
          "LoanProduct/getloanproductfees/" +
            this.productTypeKey.loanProductKey +
            "/" +
            this.loanAmount +
            "/" +
            this.repaymentInstallments
        )
          .then((res) => {
            this.loanFees = res.data.data;
            this.getLoansNotRequired();
          })
          .catch((error) => {
            this.alertError(error?.message);
          });
      }, 300);
    },
  },
  watch: {
    productTypeKey: function (id) {
      this.setSelectedProduct(id);
      this.getProductFees();
    },
    "form.selectedCustomer": function () {
      this.loanName = this.form.selectedCustomer.customerNameWithID;
    },
    show(change) {
      if (change) {
        if (window.location.hash.indexOf("customer") != -1) {
          this.whatPage = "customer";
        }
      }
    },
    //products(change) {
    //if (change && Array.isArray(change)) {
    //this.loanProduct = change[0];
    //this.setSelectedProduct(change[0].loanProductKey);
    //this.$v.$reset();
    //}
    //},
    "disbursementDetails.firstRepaymentDate": function () {
      this.scheduleGenerationError = null;
    },
  },
  computed: {
    ...mapState({
      enums: (state) => state.loan.enums,
      loading: (state) => state.loan.loading_all,
      products: (state) =>
        state.loan.products_all
          ? state.loan.products_all.data
            ? state.loan.products_all.data
            : []
          : [],
      saving: (state) => state.loan.saving,
      banks: (state) => state.banking.allActiveBanks,
      interestRateDisplay() {
        const unit = this.enums.howIsTheInterestRateCharged.find(
          (unit) => unit.id === Number(this.interestType)
        );
        if (unit) {
          return `(${unit.name})`;
        } else {
          return "";
        }
      },
    }),
    sortedBanks() {
      const banksArray = this.banks;
      return banksArray.sort((a, b) => a.name.localeCompare(b.name));
    },
    sortedEmployers() {
      const employerArray = this.allEmployers;
      return employerArray.sort((a, b) => a.name.localeCompare(b.name));
    },
    sortedCustomers() {
      const user = this.customers.map((item) => {
        return {
          ...item,
          accountFullName: item.customerFirstName + " " + item.customerLastName,
        };
      });
      return user;
    },
    sortedUsers() {
      const userArray = this.allUsers;
      return userArray.sort((a, b) => a.name.localeCompare(b.name));
    },
    sortedGroups() {
      const userArray = this.allActiveGroups;
      return userArray.sort((a, b) => a.groupName.localeCompare(b.groupName));
    },
    newRepayments() {
      if (this.repayments.repayments) {
        const newRep = this.repayments.repayments;
        newRep.shift();
        return this.repayments.repayments;
      }
      return null;
    },
    loanHasFees() {
      return this.loanFees.loanProductFees
        ? this.loanFees.loanProductFees.length > 0
        : false;
    },
    requiredLoanFees() {
      return (
        this.loanFees.loanProductFees.filter(
          (fee) => fee.isRequired === true
        ) ?? null
      );
    },
    loanFeesNotRequired() {
      const loanFees =
        this.loanFees.loanProductFees.filter(
          (fee) => fee.isRequired !== true
        ) ?? null;
      return loanFees;
    },
    totalLoansNotRequired() {
      return this.loanFeesNotRequired.length;
    },
    getFeeDifference() {
      return this.loanFeesNotRequired.length - this.newLoanFees.length === 0;
    },
    isFromCustomer() {
      const { customer_id } = this.$route.query;
      if (customer_id) return true;
      return false;
    },
  },
  mounted() {
    const self = this;
    this.listBranches().then((res) => {
      self.loadingBranchList = false;
      if (res.status === 200) {
        self.branchList = res.data.data.items;
      }
    });
    this.getLoanProducts();
    this.getAllBanks();
    this.setDefaultCustomer();
    //this.setDefaultProduct();
    this.getAllCustomers();
    this.getAllEmployers();
    this.getAllUsers();
  },

  destroyed() {
    this.form.selectedCustomer = {};
  },
};
</script>

<style lang="scss" scoped>
.modal__head__subtitle {
  font-weight: 600;
  font-family: "Montserrat-semibold";
  font-size: 16px;
  line-height: 28px;
  color: #1e1f20;
}

.modal__head__subtitled {
  font-weight: 600;
  font-family: "Montserrat-semibold";
  font-size: 16px;
  line-height: 28px;
  color: #1e1f20;
}
</style>
